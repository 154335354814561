// import logo from './logo.svg';
import React, { useState, useRef, useEffect } from 'react';
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils, faMapSigns, faBox, faPlateWheat, faMobileScreen, faCogs, faUsers, faChartLine, faTruckFast } from '@fortawesome/free-solid-svg-icons'
import SliderCards from '../components/Slider';
import CustomerSlider from '../components/customer';
import Valueslider from '../components/Values';
import { Dropdown } from 'semantic-ui-react'
import Selector from '../components/dropdown';
import ContactForm from '../components/contactForm';
import ReactGA from 'react-ga'
import { Link } from 'react-router-dom'

const TRACKING_ID = "UA-151006169-1"
ReactGA.initialize(TRACKING_ID);

export function Home() {
    const sales = useRef(null);
    const [order, setOrder] = useState();
    const [avgOrder, setAvgOrder] = useState();
    const [currency, setCurrency] = useState();

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    const scrollToSection = (elementRef) => {
        window.scrollTo({
            top: elementRef.current.offsetTop,
            behavior: "smooth",
        })
    }
    const handleOrder = (event) => {
        setOrder(event.target.value);
    };
    const handleAvgOrder = (event) => {
        setAvgOrder(event.target.value);
    };
    const handleCurrency = (data) => {
        // console.log("currency data", data)
        setCurrency(data);
    };

    return (
        console.log("functions", order, avgOrder, currency),
        <div className="App">

            {/* navbar */}
            {/* -------///------- */}
            <div className='flex flex-row h-6 pt-8 px-4 2xl:px-48 xl:24 lg:px-16 md:px-12 sm:px-8 '>
                <div className="flex-1 text-left font-bold text-2xl md:text-3xl lg:text-4xl text-green-600">QuickFood</div>
                <Selector showCurrency={handleCurrency} />
                {/* <div className="basis-1/2">03</div> */}
            </div>
            {/* navbar */}
            {/* -------///------- */}


            {/* first Section */}
            {/* -------///------- */}
            <div className='flex flex-col lg:flex-row px-4 items-center pt-24 md:pt-48 2xl:px-48 xl:24 lg:px-16 md:px-12 sm:px-8'>
                <div className='basis-1/3 mb-16 lg:mb-0'>
                    <div className="text-left font-sm text-3xl md:text-5xl">
                        Online ordering <br />
                        system for <br />
                        <a class="underline decoration-green-500 font-medium">restaurants</a>
                    </div>
                    <div className="text-left text-md py-8" >
                        QuickFood has everything you need to accept orders directly from your own websites and apps, provide digital ordering from kiosks or phones, and develop your business through marketing and loyalty schemes.
                    </div>
                    <div
                        onClick={() => scrollToSection(sales)}
                        className='w-36 md:w-56 border border-white-800 px-4 text-left text-base md:text-xl py-2 md:py-4 transition ease-in-out delay-50 bg-gradient-to-r from-green-400 to-green-400 hover:from-green-200 hover:to-green-300 ... hover:drop-shadow-xl  hover:-translate-y-1  hover:scale-110'>
                        Talk to Sales
                    </div>

                </div>

                <div className="basis-1/2 grow" >
                    <img className="max-w-full h-auto" src={require('../images/elmelo1.jpg')} alt="..." />
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240"><path fill="#F3F4F6" fill-opacity="1" d="M0,160L30,149.3C60,139,120,117,180,101.3C240,85,300,75,360,85.3C420,96,480,128,540,149.3C600,171,660,181,720,160C780,139,840,85,900,85.3C960,85,1020,139,1080,181.3C1140,224,1200,256,1260,224C1320,192,1380,96,1410,48L1440,0L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg>
            {/* end first Section */}
            {/* -------///------- */}


            {/* second Section */}
            {/* -------///------- */}
            <div className='bg-gray-100 pb-80 pt-8 2xl:px-48 xl:24 lg:px-16 md:px-12 sm:px-8'>
                <p className='text-center'>Trusted by restaurants and chains across the UK:</p>
                <div className='flex flex-row flex-wrap justify-center items-center'>
                    <a href="https://www.tiffinsabingdon.com/" target="_blank" className="flex-none lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50 relative">
                        <img className="w-28 h-auto md:w-64 md:h-auto mx-auto lg:max-w-full lg:h-auto" src={require('../images/tiffins.jpg')} alt="..." />
                        {/* <div class="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center bg-black bg-opacity-75 items-center text-xl md:text-2xl lg:text-4xl text-white">
                            Indian <br />
                            Abingdon
                        </div> */}
                    </a>
                    <a href="https://www.majliss.co.uk/" target="_blank" className="flex-none lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition relative ease-in-out delay-50">
                        <img className="w-28 h-auto md:w-64 md:h-auto mx-auto lg:max-w-full lg:h-auto" src={require('../images/Majliss.jpg')} alt="..." />
                        {/* <div class="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center bg-black bg-opacity-75 items-center text-xl md:text-2xl lg:text-4xl text-white">
                            Indian <br />
                            Oxford
                        </div> */}
                    </a>
                    <a href="https://losdosamigos.quickfood.io/" target="_blank" className="flex-none lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition relative ease-in-out delay-50">
                        <img className="w-28 h-auto md:w-64 md:h-auto mx-auto lg:max-w-full lg:h-auto" src={require('../images/losdos.jpg')} alt="..." />
                        {/* <div class="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center bg-black bg-opacity-75 items-center text-xl md:text-2xl lg:text-4xl text-white">
                            Mexican <br />
                            Portsmouth
                        </div> */}
                    </a>

                    <a href="https://www.abingdonspice.co.uk/" target="_blank" className="flex-none lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition relative ease-in-out delay-50">
                        <img className="w-28 h-auto md:w-64 md:h-auto mx-auto lg:max-w-full lg:h-auto" src={require('../images/asp.jpeg')} alt="..." />
                        {/* <div class="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center bg-black bg-opacity-75 items-center text-xl md:text-2xl lg:text-4xl text-white">
                            Indian <br />
                            Abingdon
                        </div> */}
                    </a>
                    {/* <div className="flex-none lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50">
                        <img className="w-28 h-auto md:w-64 md:h-auto mx-auto lg:max-w-full lg:h-auto" src={require('../images/tiffins.jpg')} alt="..." />

                    </div>
                    <div className="flex-none lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50">
                        <img className="w-28 h-auto md:w-64 md:h-auto mx-auto lg:max-w-full lg:h-auto" src={require('../images/Majliss.png')} alt="..." />

                    </div>
                    <div className="flex-none lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50">
                        <img className="w-28 h-auto md:w-64 md:h-auto mx-auto lg:max-w-full lg:h-auto" src={require('../images/Dalchini.png')} alt="..." />

                    </div>
                    <div className="flex-none lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50">
                        <img className="w-28 h-auto md:w-64 md:h-auto mx-auto lg:max-w-full lg:h-auto" src={require('../images/abingdonSpice.png')} alt="..." />

                    </div> */}
                </div>
                <div className='flex flex-row flex-wrap justify-center items-center'>
                    <a href="https://antiquepizza.com/" target="_blank" className="flex-none relative lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50">
                        <img className="w-28 h-auto md:w-64 md:h-auto mx-auto lg:max-w-full lg:h-auto" src={require('../images/antique.png')} alt="..." />
                        {/* <div class="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center bg-black bg-opacity-75 items-center text-xl md:text-2xl lg:text-4xl text-white">
                            Italian <br />
                            Oxford
                        </div> */}
                    </a>
                    <a href="https://nativ.quickfood.io/" target="_blank" className="flex-none relative lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50">
                        <img className="w-28 h-auto md:w-64 md:h-auto mx-auto lg:max-w-full lg:h-auto" src={require('../images/nativ.png')} alt="..." />
                        {/* <div class="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center bg-black bg-opacity-75 items-center text-xl md:text-2xl lg:text-4xl text-white">
                            African <br />
                            Bournemouth
                        </div> */}
                    </a>
                    <a href="https://ydees.quickfood.io/" target="_blank" className="flex-none relative lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50">
                        <img className="w-28 h-auto md:w-64 md:h-auto mx-auto lg:max-w-full lg:h-auto" src={require('../images/yd.png')} alt="..." />
                        {/* <div class="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center bg-black bg-opacity-75 items-center text-xl md:text-2xl lg:text-4xl text-white">
                            Jamaican <br />
                            Dudley
                        </div> */}
                    </a>

                    <a href="https://nemrut.quickfood.io/" target="_blank" className="flex-none relative lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50">
                        <img className="w-28 h-auto md:w-64 md:h-auto mx-auto lg:max-w-full lg:h-auto" src={require('../images/nemrut.png')} alt="..." />
                        {/* <div class="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center bg-black bg-opacity-75 items-center text-xl md:text-2xl lg:text-4xl text-white">
                            Mediterranean <br />
                            Portsmouth
                        </div> */}
                    </a>
                    {/* <div className="flex-none lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50">
                        <img className="w-28 h-auto md:w-64 md:h-auto mx-auto lg:max-w-full lg:h-auto" src={require('../images/tiffins.jpg')} alt="..." />

                    </div>
                    <div className="flex-none lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50">
                        <img className="w-28 h-auto md:w-64 md:h-auto mx-auto lg:max-w-full lg:h-auto" src={require('../images/Majliss.png')} alt="..." />

                    </div>
                    <div className="flex-none lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50">
                        <img className="w-28 h-auto md:w-64 md:h-auto mx-auto lg:max-w-full lg:h-auto" src={require('../images/Dalchini.png')} alt="..." />

                    </div>
                    <div className="flex-none lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50">
                        <img className="w-28 h-auto md:w-64 md:h-auto mx-auto lg:max-w-full lg:h-auto" src={require('../images/abingdonSpice.png')} alt="..." />

                    </div> */}
                </div>
                <p className='text-center'>Succeed with everything you need:</p>
                <div className='flex flex-col lg:flex-row'>
                    <div className="flex-1 text-left bg-white m-8 px-8 py-8 transition ease-in-out delay-50 hover:drop-shadow-2xl hover:-translate-y-1  hover:scale-120'">
                        <div className='flex flex-row lg:flex-col items-start'>
                            <FontAwesomeIcon className="block mb-2 md:mb-8 mr-4" icon={faUtensils} color="#22c55e" size="2x" />
                            <a class="underline decoration-green-500 font-medium text-xl md:text-2xl">Order</a>
                        </div>
                        <div className="text-lg md:text-xl my-4 md:my-8 text-left">Take orders via simple websites, apps, kiosks, and even from your tables.</div>
                    </div>
                    <div className="flex-1 text-left bg-white m-8 px-8 py-8 transition ease-in-out delay-50 hover:drop-shadow-2xl hover:-translate-y-1  hover:scale-120'">
                        <div className='flex flex-row lg:flex-col items-start'>
                            <FontAwesomeIcon className="block mb-2 md:mb mr-4" icon={faChartLine} color="#22c55e" size="2x" />
                            <a class="underline decoration-green-500 font-medium text-xl md:text-2xl">Grow</a>
                        </div>
                        <div className="text-lg md:text-xl my-4 md:my-8 text-left">Built-in marketing to help build relationships with customers and grow your business.</div>
                    </div>
                    <div className="flex-1 text-left bg-white m-8 px-8 py-8 transition ease-in-out delay-50 hover:drop-shadow-2xl hover:-translate-y-1  hover:scale-120'">
                        <div className='flex flex-row lg:flex-col items-start'>
                            <FontAwesomeIcon className="block mb-2 md:mb mr-4" icon={faTruckFast} color="#22c55e" size="2x" />
                            <a class="underline decoration-green-500 font-medium text-xl md:text-2xl">Deliver</a>
                        </div>
                        <div className="text-lg md:text-xl my-4 md:my-8 text-left">We’ll help with sorting on-demand delivery - without the crazy commissions.</div>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: "-320px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240"><path fill="#fff" fill-opacity="1" d="M0,160L30,149.3C60,139,120,117,180,101.3C240,85,300,75,360,85.3C420,96,480,128,540,149.3C600,171,660,181,720,160C780,139,840,85,900,85.3C960,85,1020,139,1080,181.3C1140,224,1200,256,1260,224C1320,192,1380,96,1410,48L1440,0L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg>
            </div>
            {/* end second Section */}
            {/* -------///------- */}

            {/* third Section */}
            {/* -------///------- */}
            <div className='bg-white pt-8 2xl:px-48 xl:24 lg:px-16 md:px-12 sm:px-8'>
                <div className='text-center text-3xl md:text-5xl font-bold mb-8'> Your profits are being taken by third party marketplaces. </div>
                <div className="flex flex-col lg:flex-row text-left drop-shadow-2xl bg-white border border-white-600 m-8 px-8 lg:px-24 py-10 lg:py-32transition ease-in-out delay-50'">
                    <div className="flex flex-col flex-1 mb-8 lg:mb-0">
                        <div className='text-xl md:text-3xl font-bold'>
                            You could save thousands by using your own online ordering system and getting orders directly.
                        </div>
                        <div className="text-lg md:text-xl my-8 text-left">
                            Find out how much money your business could save if you used your own website or app by using our simple calculator, this is done by comparing the standard 30% fee on marketplaces.
                        </div>

                        <div className='border px-4 md:px-8 pb-6 drop-shadow-xl bg-white mb-8'>
                            <div className='flex flex-row align-center my-2 md:my-4'>
                                <div className='text-base md:text-xl'>Orders Per Month: </div>
                                <div className='text-base md:text-xl font-bold mx-4'>
                                    {order}
                                </div>
                            </div>

                            <label class="block">
                                <input
                                    type="number"
                                    class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-green-500 focus:ring-green-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                    placeholder="Orders per month"
                                    value={order}
                                    onChange={handleOrder}
                                />
                            </label>
                        </div>

                        <div className='border px-4 md:px-8 pb-6 drop-shadow-xl bg-white mb-8'>
                            <div className='flex flex-row align-center my-4'>
                                <div className='text-base md:text-xl'>Average Order Value: </div>
                                <div className='text-base md:text-xl font-bold mx-4'>
                                    {avgOrder}
                                </div>
                            </div>

                            <label class="block">
                                <input
                                    type="number"
                                    class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-green-500 focus:ring-green-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                    placeholder="Orders per month"
                                    value={avgOrder}
                                    onChange={handleAvgOrder}
                                />
                            </label>


                        </div>
                    </div>
                    <div className="lg:mx-8 flex flex-1 flex-col align-center justify-center">
                        <div className=' bg-gray-100 py-4 md:py-12 px-8 lg:px-20'>
                            <div className="font-semibold text-2xl md:text-3xl py-4 text-gray-400">Saving/Month</div>
                            {
                                (order && avgOrder) ?
                                    <div className="text-3xl md:text-5xl font-bold py-4  text-green-500">
                                        {/* € 6 */}
                                        {currency} {parseInt((order * avgOrder) / 4.347826087)}
                                    </div>
                                    :
                                    <div className="text-3xl md:text-5xl font-bold py-4  text-green-500">
                                        {/* € 6 */}
                                        {currency} 0
                                    </div>
                            }

                            <div className="font-semibold text-2xl md:text-3xl py-4 text-gray-400">Saving/Year</div>
                            {
                                (order && avgOrder) ?
                                    <div className="text-3xl md:text-5xl font-bold py-4  text-green-500">
                                        {/* € 6 */}
                                        {currency} {parseInt(((order * avgOrder) / 4.347826087) * 12)}
                                    </div>
                                    :
                                    <div className="text-3xl md:text-5xl font-bold py-4 text-green-500">
                                        {/* € 6 */}
                                        {currency} 0
                                    </div>
                            }
                        </div>

                    </div>
                </div>
                <div className='text-center pt-12 md:pt-24 pt-8 md:pb-16 text-3xl md:text-5xl font-bold mb-8 px-4'> QuickFood works with businesses like yours.</div>
                <div className='flex flex-col lg:flex-row'>
                    <div className="flex-1 text-left border border-white-600 m-8 px-8 py-8 transition ease-in-out delay-50 bg-gradient-to-r from-white to-white hover:from-green-200 hover:to-green-300 ... hover:drop-shadow-2xl hover:-translate-y-1  hover:scale-120'">
                        <div className='flex flex-row lg:flex-col items-start'>
                            <FontAwesomeIcon className="block mb-4 md:mb-8 mr-4" icon={faUtensils} color="#22c55e" size="2x" />
                            <a class="underline decoration-green-500 font-medium text-xl md:text-2xl">Restaurants.</a>
                        </div>
                        <div className="text-lg md:text-xl my-4 md:my-8 text-left">The easy way to add collection, delivery, or digital table and kiosk ordering is by using QuickFood.</div>
                    </div>
                    <div className="flex-1 text-left border border-white-600 m-8 px-8 py-8 transition ease-in-out delay-50 bg-gradient-to-r from-white to-white hover:from-green-200 hover:to-green-300 ... hover:drop-shadow-2xl hover:-translate-y-1  hover:scale-120'">
                        <div className='flex flex-row lg:flex-col items-start'>
                            <FontAwesomeIcon className="block mb-4 md:mb-8 mr-4" icon={faBox} color="#22c55e" size="2x" />
                            <a class="underline decoration-green-500 font-medium text-xl md:text-2xl">Takeaways.</a>
                        </div>
                        <div className="text-lg md:text-xl my-4 md:my-8 text-left">
                            Grow revenue and really start building your brand, leave the aggregators behind.
                        </div>
                    </div>
                    <div className="flex-1 text-left border border-white-600 m-8 px-8 py-8 transition ease-in-out delay-50 bg-gradient-to-r from-white to-white hover:from-green-200 hover:to-green-300 ... hover:drop-shadow-2xl hover:-translate-y-1  hover:scale-120'">
                        <div className='flex flex-row lg:flex-col items-start'>
                            <FontAwesomeIcon className="block mb-4 md:mb-8 mr-4" icon={faMapSigns} color="#22c55e" size="2x" />
                            <a class="underline decoration-green-500 font-medium text-xl md:text-2xl">Chains.</a>
                        </div>
                        <div className="text-lg md:text-xl my-4 md:my-8 text-left">The perfect solution for multiple-store chains and franchises is QuickFood.</div>
                    </div>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240"><path fill="#F3F4F6" fill-opacity="1" d="M0,160L30,149.3C60,139,120,117,180,101.3C240,85,300,75,360,85.3C420,96,480,128,540,149.3C600,171,660,181,720,160C780,139,840,85,900,85.3C960,85,1020,139,1080,181.3C1140,224,1200,256,1260,224C1320,192,1380,96,1410,48L1440,0L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg>
            {/* third Section end */}
            {/* -------///------- */}

            {/* fourth Section */}
            {/* -------///------- */}
            <div className='bg-gray-100 pb-48 pt-8 2xl:px-48 xl:24 lg:px-16 md:px-12 sm:px-8'>
                <div className='text-center text-3xl mx-8 md:mx-0 md:text-5xl font-bold mb-8'> Everything needed to grow your business.</div>
                <SliderCards />
            </div>
            <div className='-mt-40 lg:-mt-80'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240"><path fill="#fff" fill-opacity="1" d="M0,160L30,149.3C60,139,120,117,180,101.3C240,85,300,75,360,85.3C420,96,480,128,540,149.3C600,171,660,181,720,160C780,139,840,85,900,85.3C960,85,1020,139,1080,181.3C1140,224,1200,256,1260,224C1320,192,1380,96,1410,48L1440,0L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg>
            </div>

            {/* fourth Section end */}
            {/* -------///------- */}

            {/* fifth Section */}
            {/* -------///------- */}
            <div className='bg-white pt-8 2xl:px-48 xl:24 lg:px-16 md:px-12 sm:px-8'>
                <div className='text-center text-3xl md:text-5xl font-bold mb-8'> Our Customers.</div>
                <CustomerSlider />
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240"><path fill="#F3F4F6" fill-opacity="1" d="M0,160L30,149.3C60,139,120,117,180,101.3C240,85,300,75,360,85.3C420,96,480,128,540,149.3C600,171,660,181,720,160C780,139,840,85,900,85.3C960,85,1020,139,1080,181.3C1140,224,1200,256,1260,224C1320,192,1380,96,1410,48L1440,0L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg>
            {/* end fifth Section */}
            {/* -------///------- */}


            {/*sixth Section */}
            {/* -------///------- */}

            <div ref={sales} className='bg-gray-100 py-8 2xl:px-48 xl:px-24 lg:px-16 md:px-12 sm:px-8'>
                <div className='flex flex-col lg:flex-row'>
                    <ContactForm />
                    <div className='flex-1'>
                        <div className='text-center text-3xl md:text-5xl font-bold mb-8 mx-8'> Talk to an expert today.</div>
                        <div className="text-lg md:text-xl m-8 text-left">The easy way to add collection, delivery, or digital table and kiosk ordering is by using QuickFood.</div>
                        <div className="flex-1 text-left border border-white-600 my-8 px-8 py-8 mx-8 transition ease-in-out delay-50 bg-gradient-to-r from-white to-white hover:from-green-200 hover:to-green-300 ... hover:drop-shadow-2xl hover:-translate-y-1  hover:scale-120'">

                            <div className='flex flex-row lg:flex-col items-start'>
                                <FontAwesomeIcon className="block mb-4 md:mb-8 mr-4" icon={faUtensils} color="#22c55e" size="2x" />
                                <a class="underline decoration-green-500 font-medium text-xl md:text-2xl">Best-in-class online ordering.</a>
                            </div>
                            <div className="text-lg md:text-xl my-8 text-left">QuickFood is optimised to be fast and easy to use for your customers - and drive your own revenues as a result.</div>
                        </div>
                        <div className="flex-1 text-left border border-white-600 my-8 px-8 py-8 mx-8 transition ease-in-out delay-50 bg-gradient-to-r from-white to-white hover:from-green-200 hover:to-green-300 ... hover:drop-shadow-2xl hover:-translate-y-1  hover:scale-120'">
                            <div className='flex flex-row lg:flex-col items-start'>
                                <FontAwesomeIcon className="block mb-4 md:mb-8 mr-4" icon={faMobileScreen} color="#22c55e" size="2x" />
                                <a class="underline decoration-green-500 font-medium text-xl md:text-2xl">A comprehensive digital ordering solution.</a>
                            </div>
                            <div className="text-lg md:text-xl my-8 text-left">We support digital ordering via website, mobile apps, in-store kiosks, table ordering and even direct from Google and Instagram.</div>
                        </div>
                        <div className="flex-1 text-left border border-white-600 my-8 px-8 py-8 mx-8 transition ease-in-out delay-50 bg-gradient-to-r from-white to-white hover:from-green-200 hover:to-green-300 ... hover:drop-shadow-2xl hover:-translate-y-1  hover:scale-120'">
                            <div className='flex flex-row lg:flex-col items-start'>
                                <FontAwesomeIcon className="block mb-4 md:mb-8 mr-4" icon={faCogs} color="#22c55e" size="2x" />
                                <a class="underline decoration-green-500 font-medium text-xl md:text-2xl">Tools to grow your business.</a>
                            </div>
                            <div className="text-lg md:text-xl my-8 text-left">QuickFood supports acquisition, loyalty and re-activiation campaigns out-of-the-box, delivered via SMS or push.</div>
                        </div>
                        <div className="flex-1 text-left border border-white-600 my-8 px-8 py-8 mx-8 transition ease-in-out delay-50 bg-gradient-to-r from-white to-white hover:from-green-200 hover:to-green-300 ... hover:drop-shadow-2xl hover:-translate-y-1  hover:scale-120'">
                            <div className='flex flex-row lg:flex-col items-start'>
                                <FontAwesomeIcon className="block mb-4 md:mb-8 mr-4" icon={faUsers} color="#22c55e" size="2x" />
                                <a class="underline decoration-green-500 font-medium text-xl md:text-2xl">A team behind you.</a>
                            </div>
                            <div className="text-lg md:text-xl my-8 text-left">Our customer success and managed marketing teams help your business gain more customers and increase order volume and average value.</div>
                        </div>

                    </div>
                </div>
            </div>
            {/*end sixth Section */}
            {/* -------///------- */}

            {/*Footer Section */}
            {/* -------///------- */}
            <div className='bg-white-100 py-8 2xl:px-48 xl:px-24 lg:px-16 md:px-12 sm:px-8 flex flex-col sm:flex-row align-start justify-start'>
                <div className='flex-1 flex flex-col flex-row items-center'>
                    <div className='mx-8 text-sm cursor-pointer'>
                        <Link to="/privacy">Privacy Policy </Link></div>
                </div>
                <div className='flex-1 text-md items-center'>© 2023<span className='text-green-600 font-bold'> QuickFood. </span>All Rights Reserved.</div>
                <div className='flex-1 flex flex-col flex-row items-center sm:items-end'>
                    <div className='text-sm cursor-pointer'>
                        <Link to="/terms">Terms & Condition</Link>
                    </div>

                </div>
            </div>
            {/*End Footer Section */}
            {/* -------///------- */}

        </div>
    );
};

