
const countryCodeList =
[
    {
        "ID": "UK",
        "Name": "United Kingdom",
        "Search": "united kingdom uk england great britain gb GBP scotland wales northern ireland",
        "Flag": "https://storage.histreet-app.com/flags/i24/United-Kingdom-Flag.png",
        "val": "+44",
        "CurrencyName": "Great British Pounds",
        "CurrencyNameAbbreviated": "GBP",
        "CurrencySymbol": "£",
        "DefaultRanking": 3,
        "TAXName": "VAT"
    },
    {
        "ID":   "USA",
        "Name": "United States",
        "Search": "US United states of America USA USD",
        "Flag": "https://storage.histreet-app.com/flags/i24/United-States-Flag.png",
        "val": "+1",
        "CurrencyName": "United States Dollar",
        "CurrencyNameAbbreviated": "USD",
        "CurrencySymbol": "$",
        "DefaultRanking": 1,
        "TAXName": "TAX"
    },
    {
        "ID":   "Canada",
        "Name": "Canada",
        "Search": "Canada CAD",
        "Flag": "https://storage.histreet-app.com/flags/i24/Canada-Flag.png",
        "val": "+1",
        "CurrencyName": "Canadian Dollar",
        "CurrencyNameAbbreviated": "CAD",
        "CurrencySymbol": "$",
        "DefaultRanking": 2,
        "TAXName": "VAT"
    },
    {
        "ID":   "Australia",
        "Name": "Australia",
        "Search": "AUS Australia",
        "Flag": "https://storage.histreet-app.com/flags/i24/australia-Flag.png",
        "val": "+61",
        "CurrencyName": "Australian Dollar",
        "CurrencyNameAbbreviated": "AUD",
        "CurrencySymbol": "$",
        "DefaultRanking": 4,
        "TAXName": "VAT"
    },
    {
        "ID":   "Abkhazia",
        "Name": "Abkhazia",
        "Search": "Abkhazia",
        
        "val": "+995",
        "CurrencyName": "Russian rouble",
        "CurrencyNameAbbreviated": "RUB",
        "CurrencySymbol": "₽",
        "DefaultRanking": 5,
        "TAXName": "VAT"
    },
    {
        "ID":   "Afghanistan",
        "Name": "Afghanistan",
        "Search": "Afghanistan",
        "Flag": "https://storage.histreet-app.com/flags/i24/Afghanistan-Flag.png",
        "val": "+93",
        "CurrencyName": "Afghan afghani",
        "CurrencyNameAbbreviated": "AFN",
        "CurrencySymbol": "Afs",
        "DefaultRanking": 6,
        "TAXName": "VAT"
    },

    {
        "ID":   "Akrotiri and Dhekelia",
        "Name": "Akrotiri and Dhekelia",
        "Search": "Akrotiri and Dhekelia",
        "Flag": "https://storage.histreet-app.com/flags/i24/United-Kingdom-Flag.png",
        "val": "+357",
        "CurrencyName": "Euro",
        "CurrencyNameAbbreviated": "EUR",
        "CurrencySymbol": "€",
        "DefaultRanking": 7,
        "TAXName": "VAT"
    },
    {
        "ID":   "Albania",
        "Name": "Albania",
        "Search": "Albania",
        "Flag": "https://storage.histreet-app.com/flags/i24/Albania-Flag.png",
        "val": "+355",
        "CurrencyName": "Albanian lek",
        "CurrencyNameAbbreviated": "ALL",
        "CurrencySymbol": "Lek",
        "DefaultRanking": 8,
        "TAXName": "VAT"
    },
    {
        "ID":   "Alderney",
        "Name": "Alderney",
        "Search": "Alderney",
        
        "val": "+44",
        "CurrencyName": "Sterling banknotes",
        "CurrencyNameAbbreviated": "GBP",
        "CurrencySymbol": "£",
        "DefaultRanking": 9,
        "TAXName": "VAT"
    },
    {
        "ID":   "Algeria",
        "Name": "Algeria",
        "Search": "Algeria",
        "CurrencyName": "Algerian dinar",
        "CurrencyNameAbbreviated": "DZD",
        "CurrencySymbol": "DA",
        "Flag": "https://storage.histreet-app.com/flags/i24/Algeria-Flag.png",
        "val": "+213",
        "DefaultRanking": 10,
        "TAXName": "VAT"
    },
    {
        "ID":   "Andorra",
        "Name": "Andorra",
        "Search": "Andorra",
        "CurrencyName": "Euro",
        "CurrencyNameAbbreviated": "EUR",
        "CurrencySymbol": "€",
        "Flag": "https://storage.histreet-app.com/flags/i24/Andorra-Flag.png",
        "val": "+376",
        "DefaultRanking": 11,
        "TAXName": "VAT"
    },
    {
        "ID":   "Angola",
        "Name": "Angola",
        "Search": "Angola",
        "CurrencyName": "Angolan kwanza",
        "CurrencyNameAbbreviated": "AOA",
        "CurrencySymbol": "Kz",
        "Flag": "https://storage.histreet-app.com/flags/i24/Angola-Flag.png",
        "val": "+244",
        "DefaultRanking": 12,
        "TAXName": "VAT"
    },
    {
        "ID":   "Anguilla",
        "Name": "Anguilla",
        "Search": "Anguilla",
        "CurrencyName": "Eastern Caribbean dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "XCD",
        "Flag": "https://storage.histreet-app.com/flags/i24/Anguilla-Flag.png",
        "val": "+1",
        "DefaultRanking": 13,
        "TAXName": "VAT"
    },
    {
        "ID":   "Antigua and Barbuda",
        "Name": "Antigua and Barbuda",
        "Search": "Antigua and Barbuda",
        "CurrencyName": "Eastern Caribbean dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "XCD",
        "Flag": "https://storage.histreet-app.com/flags/i24/Antigua-and-Barbuda.png",
        "val": "+1",
        "DefaultRanking": 14,
        "TAXName": "VAT"
    },
    {
        "ID":   "Argentina",
        "Name": "Argentina",
        "Search": "Argentina",
        "CurrencyName": "Argentine peso",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "ARS",
        "Flag": "https://storage.histreet-app.com/flags/i24/Argentina-Flag.png",
        "val": "+54",
        "DefaultRanking": 15,
        "TAXName": "VAT"
    },
    {
        "ID":   "Armenia",
        "Name": "Armenia",
        "Search": "Armenia",
        "CurrencyName": "Armenian dram",
        "CurrencySymbol": "֏",
        "CurrencyNameAbbreviated": "AMD",
        "Flag": "https://storage.histreet-app.com/flags/i24/Armenia-Flag.png",
        "val": "+374",
        "DefaultRanking": 16,
        "TAXName": "VAT"
    },
    {
        "ID":   "Artsakh",
        "Name": "Artsakh",
        "Search": "Artsakh",
        "CurrencyName": "Armenian dram",
        "CurrencySymbol": "֏",
        "CurrencyNameAbbreviated": "AMD",
        
        "val": "+374",
        "DefaultRanking": 17,
        "TAXName": "VAT"
    },
    {
        "ID":   "Aruba",
        "Name": "Aruba",
        "Search": "Aruba",
        "CurrencyName": "Aruban florin",
        "CurrencySymbol": "ƒ",
        "CurrencyNameAbbreviated": "AWG",
        "Flag": "https://storage.histreet-app.com/flags/i24/aruba.png",
        "val": "+297",
        "DefaultRanking": 18,
        "TAXName": "VAT"
    },
    {
        "ID":   "Ascension Island",
        "Name": "Ascension Island",
        "Search": "Ascension Island",
        "CurrencyName": "Saint Helena pound",
        "CurrencySymbol": "£",
        "CurrencyNameAbbreviated": "SHP",
        
        "val": "+247",
        "DefaultRanking": 19,
        "TAXName": "VAT"
    },
    {
        "ID":   "Austria",
        "Name": "Austria",
        "Search": "Austria",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/at.png",
        "val": "+43",
        "DefaultRanking": 20,
        "TAXName": "VAT"
    },
    {
        "ID":   "Azerbaijan",
        "Name": "Azerbaijan",
        "Search": "Azerbaijan",
        "CurrencyName": "Azerbaijani manat",
        "CurrencySymbol": "₼",
        "CurrencyNameAbbreviated": "AZN",
        "Flag": "https://storage.histreet-app.com/flags/i24/az.png",
        "val": "+994",
        "DefaultRanking": 21,
        "TAXName": "VAT"
    },
    {
        "ID":   "Bahamas",
        "Name": "Bahamas",
        "Search": "Bahamas",
        "CurrencyName": "Bahamian dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "BSD",
        "Flag": "https://storage.histreet-app.com/flags/i24/bs.png",
        "val": "+1",
        "DefaultRanking": 22,
        "TAXName": "VAT"
    },
    {
        "ID":   "Bahrain",
        "Name": "Bahrain",
        "Search": "Bahrain",
        "CurrencyName": "Bahraini dinar",
        "CurrencySymbol": "BD",
        "CurrencyNameAbbreviated": "BHD",
        "Flag": "https://storage.histreet-app.com/flags/i24/bh.png",
        "val": "+973",
        "DefaultRanking": 23,
        "TAXName": "VAT"
    },
    {
        "ID":   "Bangladesh",
        "Name": "Bangladesh",
        "Search": "Bangladesh",
        "CurrencyName": "Bangladeshi taka",
        "CurrencySymbol": "৳",
        "CurrencyNameAbbreviated": "BDT",
        "Flag": "https://storage.histreet-app.com/flags/i24/bd.png",
        "val": "+880",
        "DefaultRanking": 24,
        "TAXName": "VAT"
    },
    {
        "ID":   "Barbados",
        "Name": "Barbados",
        "Search": "Barbados",
        "CurrencyName": "Barbadian dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "BBD",
        "Flag": "https://storage.histreet-app.com/flags/i24/bb.png",
        "val": "+1",
        "DefaultRanking": 25,
        "TAXName": "VAT"
    },
    {
        "ID":   "Belarus",
        "Name": "Belarus",
        "Search": "Belarus",
        "CurrencyName": "Belarusian rubel",
        "CurrencySymbol": "Rbls",
        "CurrencyNameAbbreviated": "BYN",
        "Flag": "https://storage.histreet-app.com/flags/i24/by.png",
        "val": "+375",
        "DefaultRanking": 26,
        "TAXName": "VAT"
    },
    {
        "ID":   "Belgium",
        "Name": "Belgium",
        "Search": "Belgium",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/be.png",
        "val": "+32",
        "DefaultRanking": 27,
        "TAXName": "VAT"
    },
    {
        "ID":   "Belize",
        "Name": "Belize",
        "Search": "Belize",
        "CurrencyName": "Belize dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "BZD",
        "Flag": "https://storage.histreet-app.com/flags/i24/bz.png",
        "val": "+501",
        "DefaultRanking": 28,
        "TAXName": "VAT"
    },
    {
        "ID":   "Benin",
        "Name": "Benin",
        "Search": "Benin",
        "CurrencyName": "West African CFA franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "XOF",
        "Flag": "https://storage.histreet-app.com/flags/i24/bj.png",
        "val": "+229",
        "DefaultRanking": 29,
        "TAXName": "VAT"
    },
    {
        "ID":   "Bermuda",
        "Name": "Bermuda",
        "Search": "Bermuda",
        "CurrencyName": "Bermudian dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "BMD",
        "Flag": "https://storage.histreet-app.com/flags/i24/bm.png",
        "val": "+1",
        "DefaultRanking": 30,
        "TAXName": "VAT"
    },
    {
        "ID":   "Bhutan",
        "Name": "Bhutan",
        "Search": "Bhutan",
        "CurrencyName": "Bhutanese ngultrum",
        "CurrencySymbol": "Nu",
        "CurrencyNameAbbreviated": "BTN",
        "Flag": "https://storage.histreet-app.com/flags/i24/bt.png",
        "val": "+975",
        "DefaultRanking": 31,
        "TAXName": "VAT"
    },
    {
        "ID":   "Bolivia",
        "Name": "Bolivia",
        "Search": "Bolivia",
        "CurrencyName": "Bolivian boliviano",
        "CurrencySymbol": "Bs",
        "CurrencyNameAbbreviated": "BOB",
        "Flag": "https://storage.histreet-app.com/flags/i24/bo.png",
        "val": "+591",
        "DefaultRanking": 32,
        "TAXName": "VAT"
    },
    {
        "ID":   "Bonaire",
        "Name": "Bonaire",
        "Search": "Bonaire",
        "CurrencyName": "United States dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "USD",
        
        "val": "+599",
        "DefaultRanking": 33,
        "TAXName": "VAT"
    },
    {
        "ID":   "Bosnia and Herzegovina",
        "Name": "Bosnia and Herzegovina",
        "Search": "Bosnia and Herzegovina",
        "CurrencyName": "Bosnia and Herzegovina convertible mark",
        "CurrencySymbol": "KM",
        "CurrencyNameAbbreviated": "BAM",
        "Flag": "https://storage.histreet-app.com/flags/i24/ba.png",
        "val": "+387",
        "DefaultRanking": 34,
        "TAXName": "VAT"
    },
    {
        "ID":   "Botswana",
        "Name": "Botswana",
        "Search": "Botswana",
        "CurrencyName": "Botswana pula",
        "CurrencySymbol": "P",
        "CurrencyNameAbbreviated": "BWP",
        "Flag": "https://storage.histreet-app.com/flags/i24/bw.png",
        "val": "+267",
        "DefaultRanking": 35,
        "TAXName": "VAT"
    },
    {
        "ID":   "Brazil",
        "Name": "Brazil",
        "Search": "Brazil",
        "CurrencyName": "Brazilian real",
        "CurrencySymbol": "R$",
        "CurrencyNameAbbreviated": "BRL",
        "Flag": "https://storage.histreet-app.com/flags/i24/br.png",
        "val": "+55",
        "DefaultRanking": 36,
        "TAXName": "VAT"
    },
    {
        "ID":   "British Indian Ocean Territory",
        "Name": "British Indian Ocean Territory",
        "Search": "British Indian Ocean Territory",
        "CurrencyName": "United States dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "USD",
        "Flag": "https://storage.histreet-app.com/flags/i24/io.png",
        "val": "+246",
        "DefaultRanking": 37,
        "TAXName": "VAT"
    },
    {
        "ID":   "British Virgin Islands",
        "Name": "British Virgin Islands",
        "Search": "British Virgin Islands",
        "CurrencyName": "United States dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "USD",
        // "Flag": "https://storage.histreet-app.com/flags/i24/placeholder.png",
        "val": "+1",
        "DefaultRanking": 38,
        "TAXName": "VAT"
    },
    {
        "ID":   "Brunei",
        "Name": "Brunei",
        "Search": "Brunei",
        "CurrencyName": "Brunei dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "BND",
        "Flag": "https://storage.histreet-app.com/flags/i24/bn.png",
        "val": "+673",
        "DefaultRanking": 39,
        "TAXName": "VAT"
    },
    {
        "ID":   "Bulgaria",
        "Name": "Bulgaria",
        "Search": "Bulgaria",
        "CurrencyName": "Bulgarian lev",
        "CurrencySymbol": "Lev",
        "CurrencyNameAbbreviated": "BGN",
        "Flag": "https://storage.histreet-app.com/flags/i24/bg.png",
        "val": "+359",
        "DefaultRanking": 40,
        "TAXName": "VAT"
    },
    {
        "ID":   "Burkina Faso",
        "Name": "Burkina Faso",
        "Search": "Burkina Faso",
        "CurrencyName": "West African CFA franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "XOF",
        "Flag": "https://storage.histreet-app.com/flags/i24/bf.png",
        "val": "+226",
        "DefaultRanking": 41,
        "TAXName": "VAT"
    },
    {
        "ID":   "Burundi",
        "Name": "Burundi",
        "Search": "Burundi",
        "CurrencyName": "Burundian franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "BIF",
        "Flag": "https://storage.histreet-app.com/flags/i24/bi.png",
        "val": "+257",
        "DefaultRanking": 42,
        "TAXName": "VAT"
    },
    {
        "ID":   "Cambodia",
        "Name": "Cambodia",
        "Search": "Cambodia",
        "CurrencyName": "Cambodian riel",
        "CurrencySymbol": "CR",
        "CurrencyNameAbbreviated": "KHR",
        "Flag": "https://storage.histreet-app.com/flags/i24/kh.png",
        "val": "+855",
        "DefaultRanking": 43,
        "TAXName": "VAT"
    },
    {
        "ID":   "Cameroon",
        "Name": "Cameroon",
        "Search": "Cameroon",
        "CurrencyName": "Central African CFA franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "XAF",
        "Flag": "https://storage.histreet-app.com/flags/i24/cm.png",
        "val": "+237",
        "DefaultRanking": 44,
        "TAXName": "VAT"
    },
    {
        "ID":   "Cape Verde",
        "Name": "Cape Verde",
        "Search": "Cape Verde",
        "CurrencyName": "Cape Verdean escudo",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "CVE",
        "Flag": "https://storage.histreet-app.com/flags/i24/cv.png",
        "val": "+238",
        "DefaultRanking": 45,
        "TAXName": "VAT"
    },
    {
        "ID":   "Cayman Islands",
        "Name": "Cayman Islands",
        "Search": "Cayman Islands",
        "CurrencyName": "Cayman Islands dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "KYD",
        "Flag": "https://storage.histreet-app.com/flags/i24/ky.png",
        "val": "+1",
        "DefaultRanking": 47,
        "TAXName": "VAT"
    },
    {
        "ID":   "Central African Republic",
        "Name": "Central African Republic",
        "Search": "Central African Republic",
        "CurrencyName": "Central African CFA franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "XAF",
        "Flag": "https://storage.histreet-app.com/flags/i24/cf.png",
        "val": "+236",
        "DefaultRanking": 48,
        "TAXName": "VAT"
    },
    {
        "ID":   "Chad",
        "Name": "Chad",
        "Search": "Chad",
        "CurrencyName": "Chilean peso",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "CLP",
        "Flag": "https://storage.histreet-app.com/flags/i24/td.png",
        "val": "+235",
        "DefaultRanking": 49,
        "TAXName": "VAT"
    },
    {
        "ID":   "Chile",
        "Name": "Chile",
        "Search": "Chile",
        "CurrencyName": "Central African CFA franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "XAF",
        "Flag": "https://storage.histreet-app.com/flags/i24/cl.png",
        "val": "+56",
        "DefaultRanking": 50,
        "TAXName": "VAT"
    },
    {
        "ID":   "China",
        "Name": "China",
        "Search": "China",
        "CurrencyName": "Renminbi",
        "CurrencySymbol": "¥",
        "CurrencyNameAbbreviated": "CNY",
        "Flag": "https://storage.histreet-app.com/flags/i24/cn.png",
        "val": "+86",
        "DefaultRanking": 51,
        "TAXName": "VAT"
    },
    {
        "ID":   "Colombia",
        "Name": "Colombia",
        "Search": "Colombia",
        "CurrencyName": "Colombian peso",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "COP",
        "Flag": "https://storage.histreet-app.com/flags/i24/co.png",
        "val": "+57",
        "DefaultRanking": 52,
        "TAXName": "VAT"
    },
    {
        "ID":   "Comoros",
        "Name": "Comoros",
        "Search": "Comoros",
        "CurrencyName": "Comorian franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "KMF",
        "Flag": "https://storage.histreet-app.com/flags/i24/km.png",
        "val": "+269",
        "DefaultRanking": 53,
        "TAXName": "VAT"
    },
    {
        "ID":   "Congo Republic",
        "Name": "Congo",
        "Search": "Congo republic",
        "CurrencyName": "Congolese franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "CDF",
        "Flag": "https://storage.histreet-app.com/flags/i24/km.png",
        "val": "+269",
        "DefaultRanking": 53,
        "TAXName": "VAT"
    },
    {
        "ID":   "Congo Democratic",
        "Name": "Congo Democratic",
        "Search": "Congo Democratic",
        "CurrencyName": "Congolese franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "CDF",
        "Flag": "https://storage.histreet-app.com/flags/i24/cd.png",
        "val": "+243",
        "DefaultRanking": 54,
        "TAXName": "VAT"
    },
    {
        "ID":   "Cook Islands",
        "Name": "Cook Islands",
        "Search": "Cook Islands",
        "CurrencyName": "New Zealand dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "NZD",
        "Flag": "https://storage.histreet-app.com/flags/i24/ck.png",
        "val": "+682",
        "DefaultRanking": 56,
        "TAXName": "VAT"
    },
    {
        "ID":   "Costa Rica",
        "Name": "Costa Rica",
        "Search": "Costa Rica",
        "CurrencyName": "Costa Rican colón",
        "CurrencySymbol": "₡",
        "CurrencyNameAbbreviated": "CRC",
        "Flag": "https://storage.histreet-app.com/flags/i24/cr.png",
        "val": "+506",
        "DefaultRanking": 57,
        "TAXName": "VAT"
    },
    {
        "ID":   "Côte d'Ivoire",
        "Name": "Côte d'Ivoire",
        "Search": "Côte d'Ivoire",
        "CurrencyName": "West African CFA franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "XOF",
        "Flag": "https://storage.histreet-app.com/flags/i24/ci.png",
        "val": "+225",
        "DefaultRanking": 58,
        "TAXName": "VAT"
    },
    {
        "ID":   "Croatia",
        "Name": "Croatia",
        "Search": "Croatia",
        "CurrencyName": "Croatian kuna",
        "CurrencySymbol": "kn",
        "CurrencyNameAbbreviated": "HRK",
        "Flag": "https://storage.histreet-app.com/flags/i24/hr.png",
        "val": "+385",
        "DefaultRanking": 59,
        "TAXName": "VAT"
    },
    {
        "ID":   "Cuba",
        "Name": "Cuba",
        "Search": "Cuba",
        "CurrencyName": "Cuban peso",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "CUP",
        "Flag": "https://storage.histreet-app.com/flags/i24/cu.png",
        "val": "+53",
        "DefaultRanking": 60,
        "TAXName": "VAT"
    },
    {
        "ID":   "Curaçao",
        "Name": "Curaçao",
        "Search": "Curaçao",
        "CurrencyName": "Netherlands Antillean guilder",
        "CurrencySymbol": "ƒ",
        "CurrencyNameAbbreviated": "ANG",
        "Flag": "https://storage.histreet-app.com/flags/i24/cw.png",
        "val": "+599",
        "DefaultRanking": 61,
        "TAXName": "VAT"
    },
    {
        "ID":   "Cyprus",
        "Name": "Cyprus",
        "Search": "Cyprus",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/cy.png",
        "val": "+357",
        "DefaultRanking": 62,
        "TAXName": "VAT"
    },
    {
        "ID":   "Czech Republic",
        "Name": "Czech Republic",
        "Search": "Czech Republic",
        "CurrencyName": "Czech koruna",
        "CurrencySymbol": "Kč",
        "CurrencyNameAbbreviated": "CZK",
        "Flag": "https://storage.histreet-app.com/flags/i24/cz.png",
        "val": "+420",
        "DefaultRanking": 63,
        "TAXName": "VAT"
    },
    {
        "ID":   "Denmark",
        "Name": "Denmark",
        "Search": "Denmark",
        "CurrencyName": "Danish krone",
        "CurrencySymbol": "kr",
        "CurrencyNameAbbreviated": "DKK",
        "Flag": "https://storage.histreet-app.com/flags/i24/dk.png",
        "val": "+45",
        "DefaultRanking": 64,
        "TAXName": "VAT"
    },
    {
        "ID":   "Djibouti",
        "Name": "Djibouti",
        "Search": "Djibouti",
        "CurrencyName": "Djiboutian franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "DJF",
        "Flag": "https://storage.histreet-app.com/flags/i24/dj.png",
        "val": "+253",
        "DefaultRanking": 65,
        "TAXName": "VAT"
    },
    {
        "ID":   "Dominica",
        "Name": "Dominica",
        "Search": "Dominica",
        "CurrencyName": "Eastern Caribbean dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "XCD",
        "Flag": "https://storage.histreet-app.com/flags/i24/dm.png",
        "val": "+1",
        "DefaultRanking": 66,
        "TAXName": "VAT"
    },
    {
        "ID":   "Dominican Republic",
        "Name": "Dominican Republic",
        "Search": "Dominican Republic",
        "CurrencyName": "Dominican peso",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "DOP",
        "Flag": "https://storage.histreet-app.com/flags/i24/do.png",
        "val": "+1",
        "DefaultRanking": 67,
        "TAXName": "VAT"
    },
    {
        "ID":   "East Timor",
        "Name": "East Timor",
        "Search": "East Timor",
        "CurrencyName": "United States dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "USD",
        
        "val": "+670",
        "DefaultRanking": 68,
        "TAXName": "VAT"
    },
    {
        "ID":   "Ecuador",
        "Name": "Ecuador",
        "Search": "Ecuador",
        "CurrencyName": "United States dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "USD",
        "Flag": "https://storage.histreet-app.com/flags/i24/ec.png",
        "val": "+593",
        "DefaultRanking": 69,
        "TAXName": "VAT"
    },
    {
        "ID":   "Egypt",
        "Name": "Egypt",
        "Search": "Egypt",
        "CurrencyName": "Egyptian pound",
        "CurrencySymbol": "LE",
        "CurrencyNameAbbreviated": "EGP",
        "Flag": "https://storage.histreet-app.com/flags/i24/eg.png",
        "val": "+20",
        "DefaultRanking": 70,
        "TAXName": "VAT"
    },
    {
        "ID":   "El Salvador",
        "Name": "El Salvador",
        "Search": "El Salvador",
        "CurrencyName": "United States dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "USD",
        "Flag": "https://storage.histreet-app.com/flags/i24/sv.png",
        "val": "+503",
        "DefaultRanking": 71,
        "TAXName": "VAT"
    },
    {
        "ID":   "Equatorial Guinea",
        "Name": "Equatorial Guinea",
        "Search": "Equatorial Guinea",
        "CurrencyName": "Central African CFA franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "XAF",
        "Flag": "https://storage.histreet-app.com/flags/i24/gq.png",
        "val": "+240",
        "DefaultRanking": 72,
        "TAXName": "VAT"
    },
    {
        "ID":   "Eritrea",
        "Name": "Eritrea",
        "Search": "Eritrea",
        "CurrencyName": "Eritrean nakfa",
        "CurrencySymbol": "Nkf",
        "CurrencyNameAbbreviated": "ERN",
        "Flag": "https://storage.histreet-app.com/flags/i24/er.png",
        "val": "+291",
        "DefaultRanking": 73,
        "TAXName": "VAT"
    },
    {
        "ID":   "Estonia",
        "Name": "Estonia",
        "Search": "Estonia",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/ee.png",
        "val": "+372",
        "DefaultRanking": 74,
        "TAXName": "VAT"
    },
    {
        "ID":   "Eswatini",
        "Name": "Eswatini",
        "Search": "Eswatini",
        "CurrencyName": "Swazi lilangeni",
        "CurrencySymbol": "E",
        "CurrencyNameAbbreviated": "SZL",
        "Flag": "https://storage.histreet-app.com/flags/i24/sz.png",
        "val": "+268",
        "DefaultRanking": 75,
        "TAXName": "VAT"
    },
    {
        "ID":   "Ethiopia",
        "Name": "Ethiopia",
        "Search": "Ethiopia",
        "CurrencyName": "Ethiopian birr",
        "CurrencySymbol": "Br",
        "CurrencyNameAbbreviated": "ETB",
        "Flag": "https://storage.histreet-app.com/flags/i24/et.png",
        "val": "+251",
        "DefaultRanking": 76,
        "TAXName": "VAT"
    },
    {
        "ID":   "Falkland Islands",
        "Name": "Falkland Islands",
        "Search": "Falkland Islands",
        "CurrencyName": "Falkland Islands pound",
        "CurrencySymbol": "£",
        "CurrencyNameAbbreviated": "FKP",
        "Flag": "https://storage.histreet-app.com/flags/i24/fk.png",
        "val": "+500",
        "DefaultRanking": 77,
        "TAXName": "VAT"
    },
    {
        "ID":   "Faroe Islands",
        "Name": "Faroe Islands",
        "Search": "Faroe Islands",
        "CurrencyName": "Danish krone",
        "CurrencySymbol": "kr",
        "CurrencyNameAbbreviated": "DKK",
        "Flag": "https://storage.histreet-app.com/flags/i24/fo.png",
        "val": "+298",
        "DefaultRanking": 78,
        "TAXName": "VAT"
    },
    {
        "ID":   "Fiji",
        "Name": "Fiji",
        "Search": "Fiji",
        "CurrencyName": "Fijian dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "FJD",
        "Flag": "https://storage.histreet-app.com/flags/i24/fj.png",
        "val": "+679",
        "DefaultRanking": 79,
        "TAXName": "VAT"
    },
    {
        "ID":   "Finland",
        "Name": "Finland",
        "Search": "Finland",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/fi.png",
        "val": "+358",
        "DefaultRanking": 80,
        "TAXName": "VAT"
    },
    {
        "ID":   "France",
        "Name": "France",
        "Search": "France",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/fr.png",
        "val": "+33",
        "DefaultRanking": 81,
        "TAXName": "VAT"
    },
    {
        "ID":   "French Polynesia",
        "Name": "French Polynesia",
        "Search": "French Polynesia",
        "CurrencyName": "CFP franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "XPF",
        "Flag": "https://storage.histreet-app.com/flags/i24/pf.png",
        "val": "+689",
        "DefaultRanking": 82,
        "TAXName": "VAT"
    },
    {
        "ID":   "Gabon",
        "Name": "Gabon",
        "Search": "Gabon",
        "CurrencyName": "Central African CFA franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "XAF",
        "Flag": "https://storage.histreet-app.com/flags/i24/ga.png",
        "val": "+241",
        "DefaultRanking": 83,
        "TAXName": "VAT"
    },
    {
        "ID":   "Gambia",
        "Name": "Gambia",
        "Search": "Gambia",
        "CurrencyName": "Gambian dalasi",
        "CurrencySymbol": "D",
        "CurrencyNameAbbreviated": "GMD",
        "Flag": "https://storage.histreet-app.com/flags/i24/gm.png",
        "val": "+220",
        "DefaultRanking": 84,
        "TAXName": "VAT"
    },
    {
        "ID":   "Georgia",
        "Name": "Georgia",
        "Search": "Georgia",
        "CurrencyName": "Georgian lari",
        "CurrencySymbol": "₾",
        "CurrencyNameAbbreviated": "GEL",
        "Flag": "https://storage.histreet-app.com/flags/i24/ge.png",
        "val": "+995",
        "DefaultRanking": 85,
        "TAXName": "VAT"
    },
    {
        "ID":   "Germany",
        "Name": "Germany",
        "Search": "Germany",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/de.png",
        "val": "+49",
        "DefaultRanking": 86,
        "TAXName": "VAT"
    },
    {
        "ID":   "Ghana",
        "Name": "Ghana",
        "Search": "Ghana",
        "CurrencyName": "Ghanaian cedi",
        "CurrencySymbol": "₵",
        "CurrencyNameAbbreviated": "GHS",
        "Flag": "https://storage.histreet-app.com/flags/i24/gh.png",
        "val": "+233",
        "DefaultRanking": 87,
        "TAXName": "VAT"
    },
    {
        "ID":   "Gibraltar",
        "Name": "Gibraltar",
        "Search": "Gibraltar",
        "CurrencyName": "Gibraltar pound",
        "CurrencySymbol": "£",
        "CurrencyNameAbbreviated": "GIP",
        "Flag": "https://storage.histreet-app.com/flags/i24/gi.png",
        "val": "+350",
        "DefaultRanking": 88,
        "TAXName": "VAT"
    },
    {
        "ID":   "Greece",
        "Name": "Greece",
        "Search": "Greece",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/gr.png",
        "val": "+30",
        "DefaultRanking": 89,
        "TAXName": "VAT"
    },
    {
        "ID":   "Greenland",
        "Name": "Greenland",
        "Search": "Greenland",
        "CurrencyName": "Danish krone",
        "CurrencySymbol": "kr",
        "CurrencyNameAbbreviated": "DKK",
        "Flag": "https://storage.histreet-app.com/flags/i24/gl.png",
        "val": "+299",
        "DefaultRanking": 90,
        "TAXName": "VAT"
    },
    {
        "ID":   "Grenada",
        "Name": "Grenada",
        "Search": "Grenada",
        "CurrencyName": "Eastern Caribbean dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "XCD",
        "Flag": "https://storage.histreet-app.com/flags/i24/gd.png",
        "val": "+1",
        "DefaultRanking": 91,
        "TAXName": "VAT"
    },
    {
        "ID":   "Guatemala",
        "Name": "Guatemala",
        "Search": "Guatemala",
        "CurrencyName": "Guatemalan quetzal",
        "CurrencySymbol": "Q",
        "CurrencyNameAbbreviated": "GTQ",
        "Flag": "https://storage.histreet-app.com/flags/i24/gt.png",
        "val": "+502",
        "DefaultRanking": 92,
        "TAXName": "VAT"
    },
    {
        "ID":   "Guernsey",
        "Name": "Guernsey",
        "Search": "Guernsey",
        "CurrencyName": "Sterling",
        "CurrencySymbol": "£",
        "CurrencyNameAbbreviated": "GBP",
        "Flag": "https://storage.histreet-app.com/flags/i24/gg.png",
        "val": "+44",
        "DefaultRanking": 93,
        "TAXName": "VAT"
    },
    {
        "ID":   "Guinea",
        "Name": "Guinea",
        "Search": "Guinea",
        "CurrencyName": "Guinean franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "GNF",
        "Flag": "https://storage.histreet-app.com/flags/i24/gn.png",
        "val": "+244",
        "DefaultRanking": 94,
        "TAXName": "VAT"
    },
    {
        "ID":   "Guinea-Bissau",
        "Name": "Guinea-Bissau",
        "Search": "Guinea-Bissau",
        "CurrencyName": "West African CFA franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "XOF",
        "Flag": "https://storage.histreet-app.com/flags/i24/gw.png",
        "val": "+245",
        "DefaultRanking": 95,
        "TAXName": "VAT"
    },
    {
        "ID":   "Guyana",
        "Name": "Guyana",
        "Search": "Guyana",
        "CurrencyName": "Guyanese dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "GYD",
        "Flag": "https://storage.histreet-app.com/flags/i24/gy.png",
        "val": "+592",
        "DefaultRanking": 96,
        "TAXName": "VAT"
    },
    {
        "ID":   "Haiti",
        "Name": "Haiti",
        "Search": "Haiti",
        "CurrencyName": "Haitian gourde",
        "CurrencySymbol": "G",
        "CurrencyNameAbbreviated": "HTG",
        "Flag": "https://storage.histreet-app.com/flags/i24/ht.png",
        "val": "+509",
        "DefaultRanking": 97,
        "TAXName": "VAT"
    },
    {
        "ID":   "Honduras",
        "Name": "Honduras",
        "Search": "Honduras",
        "CurrencyName": "Honduran lempira",
        "CurrencySymbol": "L",
        "CurrencyNameAbbreviated": "HNL",
        "Flag": "https://storage.histreet-app.com/flags/i24/hn.png",
        "val": "+504",
        "DefaultRanking": 98,
        "TAXName": "VAT"
    },
    {
        "ID":   "Hong Kong",
        "Name": "Hong Kong",
        "Search": "Hong Kong",
        "CurrencyName": "Hong Kong dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "HKD",
        "Flag": "https://storage.histreet-app.com/flags/i24/hk.png",
        "val": "+852",
        "DefaultRanking": 99,
        "TAXName": "VAT"
    },
    {
        "ID":   "Hungary",
        "Name": "Hungary",
        "Search": "Hungary",
        "CurrencyName": "Hungarian forint",
        "CurrencySymbol": "Ft",
        "CurrencyNameAbbreviated": "HUF",
        "Flag": "https://storage.histreet-app.com/flags/i24/hu.png",
        "val": "+36",
        "DefaultRanking": 100,
        "TAXName": "VAT"
    },
    {
        "ID":   "Iceland",
        "Name": "Iceland",
        "Search": "Iceland",
        "CurrencyName": "Icelandic króna",
        "CurrencySymbol": "kr",
        "CurrencyNameAbbreviated": "ISK",
        "Flag": "https://storage.histreet-app.com/flags/i24/is.png",
        "val": "+354",
        "DefaultRanking": 101,
        "TAXName": "VAT"
    },
    {
        "ID":   "India",
        "Name": "India",
        "Search": "India",
        "CurrencyName": "Indian rupee",
        "CurrencySymbol": "₹",
        "CurrencyNameAbbreviated": "INR",
        "Flag": "https://storage.histreet-app.com/flags/i24/in.png",
        "val": "+91",
        "DefaultRanking": 102,
        "TAXName": "VAT"
    },
    {
        "ID":   "Indonesia",
        "Name": "Indonesia",
        "Search": "Indonesia",
        "CurrencyName": "Indonesian rupiah",
        "CurrencySymbol": "Rp",
        "CurrencyNameAbbreviated": "IDR",
        "Flag": "https://storage.histreet-app.com/flags/i24/id.png",
        "val": "+62",
        "DefaultRanking": 103,
        "TAXName": "VAT"
    },
    {
        "ID":   "Iran",
        "Name": "Iran",
        "Search": "Iran",
        "CurrencyName": "Iranian rial",
        "CurrencySymbol": "Rls",
        "CurrencyNameAbbreviated": "IRR",
        "Flag": "https://storage.histreet-app.com/flags/i24/ir.png",
        "val": "+98",
        "DefaultRanking": 104,
        "TAXName": "VAT"
    },
    {
        "ID":   "Iraq",
        "Name": "Iraq",
        "Search": "Iraq",
        "CurrencyName": "Iraqi dinar",
        "CurrencySymbol": "ID",
        "CurrencyNameAbbreviated": "IQD",
        "Flag": "https://storage.histreet-app.com/flags/i24/iq.png",
        "val": "+964",
        "DefaultRanking": 105,
        "TAXName": "VAT"
    },
    {
        "ID":   "Ireland",
        "Name": "Ireland",
        "Search": "Ireland",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/ie.png",
        "val": "+353",
        "DefaultRanking": 106,
        "TAXName": "VAT"
    },
    {
        "ID":   "Isle of Man",
        "Name": "Isle of Man",
        "Search": "Isle of Man",
        "CurrencyName": "Sterling",
        "CurrencySymbol": "£",
        "CurrencyNameAbbreviated": "GBP",
        "Flag": "https://storage.histreet-app.com/flags/i24/im.png",
        "val": "+44",
        "DefaultRanking": 107,
        "TAXName": "VAT"
    },
    {
        "ID":   "Israel",
        "Name": "Israel",
        "Search": "Israel",
        "CurrencyName": "Israeli new shekel",
        "CurrencySymbol": "₪",
        "CurrencyNameAbbreviated": "ILS",
        "Flag": "https://storage.histreet-app.com/flags/i24/il.png",
        "val": "+972",
        "DefaultRanking": 108,
        "TAXName": "VAT"
    },
    {
        "ID":   "Italy",
        "Name": "Italy",
        "Search": "Italy",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/it.png",
        "val": "+39",
        "DefaultRanking": 109,
        "TAXName": "VAT"
    },
    {
        "ID":   "Jamaica",
        "Name": "Jamaica",
        "Search": "Jamaica",
        "CurrencyName": "Jamaican dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "JMD",
        "Flag": "https://storage.histreet-app.com/flags/i24/jm.png",
        "val": "+1",
        "DefaultRanking": 110,
        "TAXName": "VAT"
    },
    {
        "ID":   "Japan",
        "Name": "Japan",
        "Search": "Japan",
        "CurrencyName": "Japanese yen",
        "CurrencySymbol": "¥",
        "CurrencyNameAbbreviated": "JPY",
        "Flag": "https://storage.histreet-app.com/flags/i24/jp.png",
        "val": "+81",
        "DefaultRanking": 111,
        "TAXName": "VAT"
    },
    {
        "ID":   "Jersey",
        "Name": "Jersey",
        "Search": "Jersey",
        "CurrencyName": "Sterling",
        "CurrencySymbol": "£",
        "CurrencyNameAbbreviated": "GBP",
        "Flag": "https://storage.histreet-app.com/flags/i24/je.png",
        "val": "+44",
        "DefaultRanking": 112,
        "TAXName": "VAT"
    },
    {
        "ID":   "Jordan",
        "Name": "Jordan",
        "Search": "Jordan",
        "CurrencyName": "Jordanian dinar",
        "CurrencySymbol": "JD",
        "CurrencyNameAbbreviated": "JOD",
        "Flag": "https://storage.histreet-app.com/flags/i24/jo.png",
        "val": "+962",
        "DefaultRanking": 113,
        "TAXName": "VAT"
    },
    {
        "ID":   "Kazakhstan",
        "Name": "Kazakhstan",
        "Search": "Kazakhstan",
        "CurrencyName": "Kazakhstani tenge",
        "CurrencySymbol": "₸",
        "CurrencyNameAbbreviated": "KZT",
        "Flag": "https://storage.histreet-app.com/flags/i24/kz.png",
        "val": "+7",
        "DefaultRanking": 114,
        "TAXName": "VAT"
    },
    {
        "ID":   "Kenya",
        "Name": "Kenya",
        "Search": "Kenya",
        "CurrencyName": "Kenyan shilling",
        "CurrencySymbol": "Shs",
        "CurrencyNameAbbreviated": "KES",
        "Flag": "https://storage.histreet-app.com/flags/i24/ke.png",
        "val": "+254",
        "DefaultRanking": 115,
        "TAXName": "VAT"
    },
    {
        "ID":   "Kiribati",
        "Name": "Kiribati",
        "Search": "Kiribati",
        "CurrencyName": "Australian dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "AUD",
        "Flag": "https://storage.histreet-app.com/flags/i24/ki.png",
        "val": "+686",
        "DefaultRanking": 116,
        "TAXName": "VAT"
    },
    {
        "ID":   "North Korea",
        "Name": "North Korea",
        "Search": "North Korea",
        "CurrencyName": "North Korean won",
        "CurrencySymbol": "₩",
        "CurrencyNameAbbreviated": "KPW",
        "Flag": "https://storage.histreet-app.com/flags/i24/kp.png",
        "val": "+850",
        "DefaultRanking": 117,
        "TAXName": "VAT"
    },
    {
        "ID":   "South Korea",
        "Name": "South Korea",
        "Search": "South Korea",
        "CurrencyName": "South Korean won",
        "CurrencySymbol": "₩",
        "CurrencyNameAbbreviated": "KPW",
        "Flag": "https://storage.histreet-app.com/flags/i24/kr.png",
        "val": "+82",
        "DefaultRanking": 118,
        "TAXName": "VAT"
    },
    {
        "ID":   "Kosovo",
        "Name": "Kosovo",
        "Search": "Kosovo",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/xk.png",
        "val": "+383",
        "DefaultRanking": 119,
        "TAXName": "VAT"
    },
    {
        "ID":   "Kuwait",
        "Name": "Kuwait",
        "Search": "Kuwait",
        "CurrencyName": "Kuwaiti dinar",
        "CurrencySymbol": "KD",
        "CurrencyNameAbbreviated": "KWD",
        "Flag": "https://storage.histreet-app.com/flags/i24/kw.png",
        "val": "+965",
        "DefaultRanking": 120,
        "TAXName": "VAT"
    },
    {
        "ID":   "Kyrgyzstan",
        "Name": "Kyrgyzstan",
        "Search": "Kyrgyzstan",
        "CurrencyName": "Kyrgyz som",
        "CurrencySymbol": "som",
        "CurrencyNameAbbreviated": "KGS",
        "Flag": "https://storage.histreet-app.com/flags/i24/kg.png",
        "val": "+996",
        "DefaultRanking": 121,
        "TAXName": "VAT"
    },
    {
        "ID":   "Laos",
        "Name": "Laos",
        "Search": "Laos",
        "CurrencyName": "Lao kip",
        "CurrencySymbol": "₭",
        "CurrencyNameAbbreviated": "LAK",
        "Flag": "https://storage.histreet-app.com/flags/i24/la.png",
        "val": "+856",
        "DefaultRanking": 122,
        "TAXName": "VAT"
    },
    {
        "ID":   "Latvia",
        "Name": "Latvia",
        "Search": "Latvia",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/lv.png",
        "val": "+371",
        "DefaultRanking": 123,
        "TAXName": "VAT"
    },
    {
        "ID":   "Lebanon",
        "Name": "Lebanon",
        "Search": "Lebanon",
        "CurrencyName": "Lebanese pound",
        "CurrencySymbol": "LL",
        "CurrencyNameAbbreviated": "LBP",
        "Flag": "https://storage.histreet-app.com/flags/i24/lb.png",
        "val": "+961",
        "DefaultRanking": 124,
        "TAXName": "VAT"
    },
    {
        "ID":   "Lesotho",
        "Name": "Lesotho",
        "Search": "Lesotho",
        "CurrencyName": "Lesotho loti",
        "CurrencySymbol": "M",
        "CurrencyNameAbbreviated": "LSL",
        "Flag": "https://storage.histreet-app.com/flags/i24/ls.png",
        "val": "+266",
        "DefaultRanking": 125,
        "TAXName": "VAT"
    },
    {
        "ID":   "Liberia",
        "Name": "Liberia",
        "Search": "Liberia",
        "CurrencyName": "Liberian dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "LRD",
        "Flag": "https://storage.histreet-app.com/flags/i24/lr.png",
        "val": "+231",
        "DefaultRanking": 126,
        "TAXName": "VAT"
    },
    {
        "ID":   "Libya",
        "Name": "Libya",
        "Search": "Libya",
        "CurrencyName": "Libyan dinar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "LD",
        "Flag": "https://storage.histreet-app.com/flags/i24/ly.png",
        "val": "+218",
        "DefaultRanking": 127,
        "TAXName": "VAT"
    },
    {
        "ID":   "Liechtenstein",
        "Name": "Liechtenstein",
        "Search": "Liechtenstein",
        "CurrencyName": "Swiss franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "CHF",
        "Flag": "https://storage.histreet-app.com/flags/i24/li.png",
        "val": "+423",
        "DefaultRanking": 128,
        "TAXName": "VAT"
    },
    {
        "ID":   "Lithuania",
        "Name": "Lithuania",
        "Search": "Lithuania",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/lt.png",
        "val": "+370",
        "DefaultRanking": 129,
        "TAXName": "VAT"
    },
    {
        "ID":   "Luxembourg",
        "Name": "Luxembourg",
        "Search": "Luxembourg",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/lu.png",
        "val": "+352",
        "DefaultRanking": 130,
        "TAXName": "VAT"
    },
    {
        "ID":   "Macau",
        "Name": "Macau",
        "Search": "Macau",
        "CurrencyName": "Macanese pataca",
        "CurrencySymbol": "MOP$",
        "CurrencyNameAbbreviated": "MOP",
        "Flag": "https://storage.histreet-app.com/flags/i24/mo.png",
        "val": "+853",
        "DefaultRanking": 131,
        "TAXName": "VAT"
    },
    {
        "ID":   "Madagascar",
        "Name": "Madagascar",
        "Search": "Madagascar",
        "CurrencyName": "Malagasy ariary",
        "CurrencySymbol": "Ar",
        "CurrencyNameAbbreviated": "MGA",
        "Flag": "https://storage.histreet-app.com/flags/i24/mg.png",
        "val": "+261",
        "DefaultRanking": 132,
        "TAXName": "VAT"
    },
    {
        "ID":   "Malawi",
        "Name": "Malawi",
        "Search": "Malawi",
        "CurrencyName": "Malawian kwacha",
        "CurrencySymbol": "K",
        "CurrencyNameAbbreviated": "MWK",
        "Flag": "https://storage.histreet-app.com/flags/i24/mw.png",
        "val": "+265",
        "DefaultRanking": 133,
        "TAXName": "VAT"
    },
    {
        "ID":   "Malaysia",
        "Name": "Malaysia",
        "Search": "Malaysia",
        "CurrencyName": "Malaysian ringgit",
        "CurrencySymbol": "RM",
        "CurrencyNameAbbreviated": "MYR",
        "Flag": "https://storage.histreet-app.com/flags/i24/my.png",
        "val": "+60",
        "DefaultRanking": 134,
        "TAXName": "VAT"
    },
    {
        "ID":   "Maldives",
        "Name": "Maldives",
        "Search": "Maldives",
        "CurrencyName": "Maldivian rufiyaa",
        "CurrencySymbol": "Rf",
        "CurrencyNameAbbreviated": "MVR",
        "Flag": "https://storage.histreet-app.com/flags/i24/mv.png",
        "val": "+960",
        "DefaultRanking": 135,
        "TAXName": "VAT"
    },
    {
        "ID":   "Mali",
        "Name": "Mali",
        "Search": "Mali",
        "CurrencyName": "West African CFA franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "XOF",
        "Flag": "https://storage.histreet-app.com/flags/i24/ml.png",
        "val": "+223",
        "DefaultRanking": 136,
        "TAXName": "VAT"
    },
    {
        "ID":   "Malta",
        "Name": "Malta",
        "Search": "Malta",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/mt.png",
        "val": "+356",
        "DefaultRanking": 137,
        "TAXName": "VAT"
    },
    {
        "ID":   "Marshall Islands",
        "Name": "Marshall Islands",
        "Search": "Marshall Islands",
        "CurrencyName": "United States dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "USD",
        "Flag": "https://storage.histreet-app.com/flags/i24/mh.png",
        "val": "+692",
        "DefaultRanking": 138,
        "TAXName": "VAT"
    },
    {
        "ID":   "Mauritania",
        "Name": "Mauritania",
        "Search": "Mauritania",
        "CurrencyName": "Mauritanian ouguiya",
        "CurrencySymbol": "UM",
        "CurrencyNameAbbreviated": "MRU",
        "Flag": "https://storage.histreet-app.com/flags/i24/mr.png",
        "val": "+222",
        "DefaultRanking": 139,
        "TAXName": "VAT"
    },
    {
        "ID":   "Mauritius",
        "Name": "Mauritius",
        "Search": "Mauritius",
        "CurrencyName": "Mauritian rupee",
        "CurrencySymbol": "Rs",
        "CurrencyNameAbbreviated": "MUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/mu.png",
        "val": "+230",
        "DefaultRanking": 140,
        "TAXName": "VAT"
    },
    {
        "ID":   "Mexico",
        "Name": "Mexico",
        "Search": "Mexico",
        "CurrencyName": "Mexican peso",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "MXN",
        "Flag": "https://storage.histreet-app.com/flags/i24/mx.png",
        "val": "+52",
        "DefaultRanking": 141,
        "TAXName": "VAT"
    },
    {
        "ID":   "Micronesia",
        "Name": "Micronesia",
        "Search": "Micronesia",
        "CurrencyName": "United States dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "USD",
        "Flag": "https://storage.histreet-app.com/flags/i24/fm.png",
        "val": "+691",
        "DefaultRanking": 142,
        "TAXName": "VAT"
    },
    {
        "ID":   "Moldova",
        "Name": "Moldova",
        "Search": "Moldova",
        "CurrencyName": "Moldovan leu",
        "CurrencySymbol": "Lei",
        "CurrencyNameAbbreviated": "MDL",
        "Flag": "https://storage.histreet-app.com/flags/i24/md.png",
        "val": "+373",
        "DefaultRanking": 143,
        "TAXName": "VAT"
    },
    {
        "ID":   "Monaco",
        "Name": "Monaco",
        "Search": "Monaco",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/mc.png",
        "val": "+377",
        "DefaultRanking": 144,
        "TAXName": "VAT"
    },
    {
        "ID":   "Mongolia",
        "Name": "Mongolia",
        "Search": "Mongolia",
        "CurrencyName": "Mongolian tögrög",
        "CurrencySymbol": "₮",
        "CurrencyNameAbbreviated": "MNT",
        "Flag": "https://storage.histreet-app.com/flags/i24/mn.png",
        "val": "+976",
        "DefaultRanking": 145,
        "TAXName": "VAT"
    },
    {
        "ID":   "Montenegro",
        "Name": "Montenegro",
        "Search": "Montenegro",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/me.png",
        "val": "+382",
        "DefaultRanking": 146,
        "TAXName": "VAT"
    },
    {
        "ID":   "Montserrat",
        "Name": "Montserrat",
        "Search": "Montserrat",
        "CurrencyName": "Eastern Caribbean dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "XCD",
        "Flag": "https://storage.histreet-app.com/flags/i24/ms.png",
        "val": "+1",
        "DefaultRanking": 147,
        "TAXName": "VAT"
    },
    {
        "ID":   "Morocco",
        "Name": "Morocco",
        "Search": "Morocco",
        "CurrencyName": "Moroccan dirham",
        "CurrencySymbol": "DH",
        "CurrencyNameAbbreviated": "MAD",
        "Flag": "https://storage.histreet-app.com/flags/i24/ma.png",
        "val": "+212",
        "DefaultRanking": 148,
        "TAXName": "VAT"
    },
    {
        "ID":   "Mozambique",
        "Name": "Mozambique",
        "Search": "Mozambique",
        "CurrencyName": "Mozambican metical",
        "CurrencySymbol": "Mt",
        "CurrencyNameAbbreviated": "MZN",
        "Flag": "https://storage.histreet-app.com/flags/i24/mz.png",
        "val": "+258",
        "DefaultRanking": 149,
        "TAXName": "VAT"
    },
    {
        "ID":   "Myanmar",
        "Name": "Myanmar",
        "Search": "Myanmar",
        "CurrencyName": "Burmese kyat",
        "CurrencySymbol": "Ks",
        "CurrencyNameAbbreviated": "MMK",
        "Flag": "https://storage.histreet-app.com/flags/i24/mm.png",
        "val": "+95",
        "DefaultRanking": 150,
        "TAXName": "VAT"
    },
    {
        "ID":   "Namibia",
        "Name": "Namibia",
        "Search": "Namibia",
        "CurrencyName": "Namibian dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "NAD",
        "Flag": "https://storage.histreet-app.com/flags/i24/na.png",
        "val": "+264",
        "DefaultRanking": 151,
        "TAXName": "VAT"
    },
    {
        "ID":   "Nauru",
        "Name": "Nauru",
        "Search": "Nauru",
        "CurrencyName": "Australian dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "AUD",
        "Flag": "https://storage.histreet-app.com/flags/i24/nr.png",
        "val": "+674",
        "DefaultRanking": 152,
        "TAXName": "VAT"
    },
    {
        "ID":   "Nepal",
        "Name": "Nepal",
        "Search": "Nepal",
        "CurrencyName": "Nepalese rupee",
        "CurrencySymbol": "Rs",
        "CurrencyNameAbbreviated": "NPR",
        "Flag": "https://storage.histreet-app.com/flags/i24/np.png",
        "val": "+977",
        "DefaultRanking": 153,
        "TAXName": "VAT"
    },
    {
        "ID":   "Netherlands",
        "Name": "Netherlands",
        "Search": "Netherlands",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/nl.png",
        "val": "+31",
        "DefaultRanking": 154,
        "TAXName": "VAT"
    },
    {
        "ID":   "New Caledonia",
        "Name": "New Caledonia",
        "Search": "New Caledonia",
        "CurrencyName": "CFP franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "XPF",
        "Flag": "https://storage.histreet-app.com/flags/i24/nc.png",
        "val": "+687",
        "DefaultRanking": 155,
        "TAXName": "VAT"
    },
    {
        "ID":   "New Zealand",
        "Name": "New Zealand",
        "Search": "New Zealand",
        "CurrencyName": "New Zealand dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "NZD",
        "Flag": "https://storage.histreet-app.com/flags/i24/nz.png",
        "val": "+64",
        "DefaultRanking": 156,
        "TAXName": "VAT"
    },
    {
        "ID":   "Nicaragua",
        "Name": "Nicaragua",
        "Search": "Nicaragua",
        "CurrencyName": "Nicaraguan córdoba",
        "CurrencySymbol": "C$",
        "CurrencyNameAbbreviated": "NIO",
        "Flag": "https://storage.histreet-app.com/flags/i24/ni.png",
        "val": "+505",
        "DefaultRanking": 157,
        "TAXName": "VAT"
    },
    {
        "ID":   "Niger",
        "Name": "Niger",
        "Search": "Niger",
        "CurrencyName": "West African CFA franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "XOF",
        "Flag": "https://storage.histreet-app.com/flags/i24/ne.png",
        "val": "+227",
        "DefaultRanking": 158,
        "TAXName": "VAT"
    },
    {
        "ID":   "Nigeria",
        "Name": "Nigeria",
        "Search": "Nigeria",
        "CurrencyName": "Nigerian naira",
        "CurrencySymbol": "₦",
        "CurrencyNameAbbreviated": "NGN",
        "Flag": "https://storage.histreet-app.com/flags/i24/ng.png",
        "val": "+234",
        "DefaultRanking": 159,
        "TAXName": "VAT"
    },
    {
        "ID":   "Niue",
        "Name": "Niue",
        "Search": "Niue",
        "CurrencyName": "New Zealand dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "NZD",
        "Flag": "https://storage.histreet-app.com/flags/i24/nu.png",
        "val": "+683",
        "DefaultRanking": 160,
        "TAXName": "VAT"
    },
    {
        "ID":   "North Macedonia",
        "Name": "North Macedonia",
        "Search": "North Macedonia",
        "CurrencyName": "Macedonian denar",
        "CurrencySymbol": "DEN",
        "CurrencyNameAbbreviated": "MKD",
        "Flag": "https://storage.histreet-app.com/flags/i24/mk.png",
        "val": "+389",
        "DefaultRanking": 161,
        "TAXName": "VAT"
    },
    {
        "ID":   "Northern Cyprus",
        "Name": "Northern Cyprus",
        "Search": "Northern Cyprus",
        "CurrencyName": "Turkish lira",
        "CurrencySymbol": "₺",
        "CurrencyNameAbbreviated": "TRY",
        // "Flag": "https://storage.histreet-app.com/flags/i24/placeholder.png",
        "val": "+90",
        "DefaultRanking": 162,
        "TAXName": "VAT"
    },
    {
        "ID":   "Norway",
        "Name": "Norway",
        "Search": "Norway",
        "CurrencyName": "Norwegian krone",
        "CurrencySymbol": "kr",
        "CurrencyNameAbbreviated": "NOK",
        "Flag": "https://storage.histreet-app.com/flags/i24/no.png",
        "val": "+47",
        "DefaultRanking": 163,
        "TAXName": "VAT"
    },
    {
        "ID":   "Oman",
        "Name": "Oman",
        "Search": "Oman",
        "CurrencyName": "Omani rial",
        "CurrencySymbol": "RO",
        "CurrencyNameAbbreviated": "OMR",
        "Flag": "https://storage.histreet-app.com/flags/i24/om.png",
        "val": "+968",
        "DefaultRanking": 164,
        "TAXName": "VAT"
    },
    {
        "ID":   "Pakistan",
        "Name": "Pakistan",
        "Search": "Pakistan",
        "CurrencyName": "Pakistani rupee",
        "CurrencySymbol": "Rs",
        "CurrencyNameAbbreviated": "PKR",
        "Flag": "https://storage.histreet-app.com/flags/i24/pk.png",
        "val": "+92",
        "DefaultRanking": 165,
        "TAXName": "VAT"
    },
    {
        "ID":   "Palau",
        "Name": "Palau",
        "Search": "Palau",
        "CurrencyName": "United States dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "USD",
        "Flag": "https://storage.histreet-app.com/flags/i24/pw.png",
        "val": "+680",
        "DefaultRanking": 166,
        "TAXName": "VAT"
    },
    {
        "ID":   "Palestine",
        "Name": "Palestine",
        "Search": "Palestine",
        "CurrencyName": "Israeli new shekel",
        "CurrencySymbol": "₪",
        "CurrencyNameAbbreviated": "ILS",
        "Flag": "https://storage.histreet-app.com/flags/i24/ps.png",
        "val": "+970",
        "DefaultRanking": 167,
        "TAXName": "VAT"
    },
    {
        "ID":   "Panama",
        "Name": "Panama",
        "Search": "Panama",
        "CurrencyName": "United States dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "USD",
        "Flag": "https://storage.histreet-app.com/flags/i24/pa.png",
        "val": "+507",
        "DefaultRanking": 168,
        "TAXName": "VAT"
    },
    {
        "ID":   "Papua New Guinea",
        "Name": "Papua New Guinea",
        "Search": "Papua New Guinea",
        "CurrencyName": "Papua New Guinean kina",
        "CurrencySymbol": "K",
        "CurrencyNameAbbreviated": "PGK",
        "Flag": "https://storage.histreet-app.com/flags/i24/pg.png",
        "val": "+675",
        "DefaultRanking": 169,
        "TAXName": "VAT"
    },
    {
        "ID":   "Paraguay",
        "Name": "Paraguay",
        "Search": "Paraguay",
        "CurrencyName": "Paraguayan guaraní",
        "CurrencySymbol": "₲",
        "CurrencyNameAbbreviated": "PYG",
        "Flag": "https://storage.histreet-app.com/flags/i24/py.png",
        "val": "+595",
        "DefaultRanking": 170,
        "TAXName": "VAT"
    },
    {
        "ID":   "Peru",
        "Name": "Peru",
        "Search": "Peru",
        "CurrencyName": "Peruvian sol",
        "CurrencySymbol": "S/",
        "CurrencyNameAbbreviated": "PEN",
        "Flag": "https://storage.histreet-app.com/flags/i24/pe.png",
        "val": "+51",
        "DefaultRanking": 171,
        "TAXName": "VAT"
    },
    {
        "ID":   "Philippines",
        "Name": "Philippines",
        "Search": "Philippines",
        "CurrencyName": "Philippine peso",
        "CurrencySymbol": "₱",
        "CurrencyNameAbbreviated": "PHP",
        "Flag": "https://storage.histreet-app.com/flags/i24/ph.png",
        "val": "+63",
        "DefaultRanking": 172,
        "TAXName": "VAT"
    },
    {
        "ID":   "Pitcairn Islands",
        "Name": "Pitcairn Islands",
        "Search": "Pitcairn Islands",
        "CurrencyName": "New Zealand dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "NZD",
        "Flag": "https://storage.histreet-app.com/flags/i24/pn.png",
        "val": "+64",
        "DefaultRanking": 173,
        "TAXName": "VAT"
    },
    {
        "ID":   "Poland",
        "Name": "Poland",
        "Search": "Poland",
        "CurrencyName": "Polish złoty",
        "CurrencySymbol": "zł",
        "CurrencyNameAbbreviated": "PLN",
        "Flag": "https://storage.histreet-app.com/flags/i24/pl.png",
        "val": "+48",
        "DefaultRanking": 174,
        "TAXName": "VAT"
    },
    {
        "ID":   "Portugal",
        "Name": "Portugal",
        "Search": "Portugal",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/pt.png",
        "val": "+351",
        "DefaultRanking": 175,
        "TAXName": "VAT"
    },
    {
        "ID":   "Qatar",
        "Name": "Qatar",
        "Search": "Qatar",
        "CurrencyName": "Qatari riyal",
        "CurrencySymbol": "QR",
        "CurrencyNameAbbreviated": "QAR",
        "Flag": "https://storage.histreet-app.com/flags/i24/qa.png",
        "val": "+974",
        "DefaultRanking": 176,
        "TAXName": "VAT"
    },
    {
        "ID":   "Romania",
        "Name": "Romania",
        "Search": "Romania",
        "CurrencyName": "Romanian leu",
        "CurrencySymbol": "Lei",
        "CurrencyNameAbbreviated": "RON",
        "Flag": "https://storage.histreet-app.com/flags/i24/ro.png",
        "val": "+40",
        "DefaultRanking": 177,
        "TAXName": "VAT"
    },
    {
        "ID":   "Russia",
        "Name": "Russia",
        "Search": "Russia",
        "CurrencyName": "Russian rouble",
        "CurrencySymbol": "₽",
        "CurrencyNameAbbreviated": "RUB",
        "Flag": "https://storage.histreet-app.com/flags/i24/ru.png",
        "val": "+7",
        "DefaultRanking": 178,
        "TAXName": "VAT"
    },
    {
        "ID":   "Rwanda",
        "Name": "Rwanda",
        "Search": "Rwanda",
        "CurrencyName": "Rwandan franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "RWF",
        "Flag": "https://storage.histreet-app.com/flags/i24/rw.png",
        "val": "+250",
        "DefaultRanking": 179,
        "TAXName": "VAT"
    },
    {
        "ID":   "Saba",
        "Name": "Saba",
        "Search": "Saba",
        "CurrencyName": "United States dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "USD",
        
        "val": "+599",
        "DefaultRanking": 179,
        "TAXName": "VAT"
    },
    {
        "ID":   "Sahrawi Republic",
        "Name": "Sahrawi Republic",
        "Search": "Sahrawi Republic",
        "CurrencyName": "Moroccan dirham",
        "CurrencySymbol": "DH",
        "CurrencyNameAbbreviated": "MAD",
        
        "val": "+212",
        "DefaultRanking": 180,
        "TAXName": "VAT"
    },
    {
        "ID":   "Saint Helena",
        "Name": "Saint Helena",
        "Search": "Saint Helena",
        "CurrencyName": "Saint Helena pound",
        "CurrencySymbol": "£",
        "CurrencyNameAbbreviated": "SHP",
        "Flag": "https://storage.histreet-app.com/flags/i24/sh.png",
        "val": "+290",
        "DefaultRanking": 181,
        "TAXName": "VAT"
    },
    {
        "ID":   "Saint Kitts and Nevis",
        "Name": "Saint Kitts and Nevis",
        "Search": "Saint Kitts and Nevis",
        "CurrencyName": "Eastern Caribbean dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "XCD",
        "Flag": "https://storage.histreet-app.com/flags/i24/kn.png",
        "val": "+1",
        "DefaultRanking": 182,
        "TAXName": "VAT"
    },
    {
        "ID":   "Saint Lucia",
        "Name": "Saint Lucia",
        "Search": "Saint Lucia",
        "CurrencyName": "Eastern Caribbean dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "XCD",
        "Flag": "https://storage.histreet-app.com/flags/i24/lc.png",
        "val": "+1",
        "DefaultRanking": 183,
        "TAXName": "VAT"
    },
    {
        "ID":   "Saint Vincent and the Grenadines",
        "Name": "Saint Vincent and the Grenadines",
        "Search": "Saint Vincent and the Grenadines",
        "CurrencyName": "Eastern Caribbean dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "XCD",
        "Flag": "https://storage.histreet-app.com/flags/i24/vc.png",
        "val": "+1",
        "DefaultRanking": 184,
        "TAXName": "VAT"
    },
    {
        "ID":   "Samoa",
        "Name": "Samoa",
        "Search": "Samoa",
        "CurrencyName": "Samoan tālā",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "WST",
        "Flag": "https://storage.histreet-app.com/flags/i24/ws.png",
        "val": "+685",
        "DefaultRanking": 185,
        "TAXName": "VAT"
    },
    {
        "ID":   "San Marino",
        "Name": "San Marino",
        "Search": "San Marino",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/sm.png",
        "val": "+378",
        "DefaultRanking": 186,
        "TAXName": "VAT"
    },
    {
        "ID":   "São Tomé and Príncipe",
        "Name": "São Tomé and Príncipe",
        "Search": "São Tomé and Príncipe",
        "CurrencyName": "São Tomé and Príncipe dobra",
        "CurrencySymbol": "Db",
        "CurrencyNameAbbreviated": "STN",
        "Flag": "https://storage.histreet-app.com/flags/i24/st.png",
        "val": "+239",
        "DefaultRanking": 187,
        "TAXName": "VAT"
    },
    {
        "ID":   "Saudi Arabia",
        "Name": "Saudi Arabia",
        "Search": "Saudi Arabia",
        "CurrencyName": "Saudi riyal",
        "CurrencySymbol": "Rls",
        "CurrencyNameAbbreviated": "SAR",
        "Flag": "https://storage.histreet-app.com/flags/i24/sa.png",
        "val": "+966",
        "DefaultRanking": 188,
        "TAXName": "VAT"
    },
    {
        "ID":   "Senegal",
        "Name": "Senegal",
        "Search": "Senegal",
        "CurrencyName": "West African CFA franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "XOF",
        "Flag": "https://storage.histreet-app.com/flags/i24/sn.png",
        "val": "+221",
        "DefaultRanking": 189,
        "TAXName": "VAT"
    },
    {
        "ID":   "Serbia",
        "Name": "Serbia",
        "Search": "Serbia",
        "CurrencyName": "Serbian dinar",
        "CurrencySymbol": "DIN",
        "CurrencyNameAbbreviated": "RSD",
        "Flag": "https://storage.histreet-app.com/flags/i24/rs.png",
        "val": "+381",
        "DefaultRanking": 190,
        "TAXName": "VAT"
    },
    {
        "ID":   "Seychelles",
        "Name": "Seychelles",
        "Search": "Seychelles",
        "CurrencyName": "Seychellois rupee",
        "CurrencySymbol": "Rs",
        "CurrencyNameAbbreviated": "SCR",
        "Flag": "https://storage.histreet-app.com/flags/i24/sc.png",
        "val": "+248",
        "DefaultRanking": 191,
        "TAXName": "VAT"
    },
    {
        "ID":   "Sierra Leone",
        "Name": "Sierra Leone",
        "Search": "Sierra Leone",
        "CurrencyName": "Sierra Leonean leone",
        "CurrencySymbol": "Le",
        "CurrencyNameAbbreviated": "SLE",
        "Flag": "https://storage.histreet-app.com/flags/i24/sl.png",
        "val": "+232",
        "DefaultRanking": 192,
        "TAXName": "VAT"
    },
    {
        "ID":   "Singapore",
        "Name": "Singapore",
        "Search": "Singapore",
        "CurrencyName": "Singapore dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "SGD",
        "Flag": "https://storage.histreet-app.com/flags/i24/sg.png",
        "val": "+65",
        "DefaultRanking": 193,
        "TAXName": "VAT"
    },
    {
        "ID":   "Sint Eustatius",
        "Name": "Sint Eustatius",
        "Search": "Sint Eustatius",
        "CurrencyName": "United States dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "USD",
        // "Flag": "https://storage.histreet-app.com/flags/i24/placeholder.png",
        "val": "+59",
        "DefaultRanking": 194,
        "TAXName": "VAT"
    },
    {
        "ID":   "Sint Maarten",
        "Name": "Sint Maarten",
        "Search": "Sint Maarten",
        "CurrencyName": "Netherlands Antillean guilder",
        "CurrencySymbol": "ƒ",
        "CurrencyNameAbbreviated": "ANG",
        "Flag": "https://storage.histreet-app.com/flags/i24/sx.png",
        "val": "+721",
        "DefaultRanking": 195,
        "TAXName": "VAT"
    },
    {
        "ID":   "Slovakia",
        "Name": "Slovakia",
        "Search": "Slovakia",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/sk.png",
        "val": "+421",
        "DefaultRanking": 196,
        "TAXName": "VAT"
    },
    {
        "ID":   "Slovenia",
        "Name": "Slovenia",
        "Search": "Slovenia",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/si.png",
        "val": "+386",
        "DefaultRanking": 197,
        "TAXName": "VAT"
    },
    {
        "ID":   "Solomon Islands",
        "Name": "Solomon Islands",
        "Search": "Solomon Islands",
        "CurrencyName": "Solomon Islands dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "SBD",
        "Flag": "https://storage.histreet-app.com/flags/i24/sb.png",
        "val": "+677",
        "DefaultRanking": 198,
        "TAXName": "VAT"
    },
    {
        "ID":   "Somalia",
        "Name": "Somalia",
        "Search": "Somalia",
        "CurrencyName": "Somali shilling",
        "CurrencySymbol": "Shs",
        "CurrencyNameAbbreviated": "SOS",
        "Flag": "https://storage.histreet-app.com/flags/i24/so.png",
        "val": "+252",
        "DefaultRanking": 199,
        "TAXName": "VAT"
    },
    {
        "ID":   "Somaliland",
        "Name": "Somaliland",
        "Search": "Somaliland",
        "CurrencyName": "Somaliland shilling",
        "CurrencySymbol": "Shs",
        "CurrencyNameAbbreviated": "SOS",
        // "Flag": "https://storage.histreet-app.com/flags/i24/placeholder.png",
        "val": "+252",
        "DefaultRanking": 200,
        "TAXName": "VAT"
    },
    {
        "ID":   "South Africa",
        "Name": "South Africa",
        "Search": "South Africa",
        "CurrencyName": "South African rand",
        "CurrencySymbol": "R",
        "CurrencyNameAbbreviated": "ZAR",
        "Flag": "https://storage.histreet-app.com/flags/i24/za.png",
        "val": "+27",
        "DefaultRanking": 201,
        "TAXName": "VAT"
    },
    {
        "ID":   "South Ossetia",
        "Name": "South Ossetia",
        "Search": "South Ossetia",
        "CurrencyName": "Russian rouble",
        "CurrencySymbol": "₽",
        "CurrencyNameAbbreviated": "RUB",
        // "Flag": "https://storage.histreet-app.com/flags/i24/placeholder.png",
        "val": "+7",
        "DefaultRanking": 202,
        "TAXName": "VAT"
    },
    {
        "ID":   "Spain",
        "Name": "Spain",
        "Search": "Spain",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/es.png",
        "val": "+34",
        "DefaultRanking": 203,
        "TAXName": "VAT"
    },
    {
        "ID":   "South Sudan",
        "Name": "South Sudan",
        "Search": "South Sudan",
        "CurrencyName": "South Sudanese pound",
        "CurrencySymbol": "SS£",
        "CurrencyNameAbbreviated": "SSP",
        "Flag": "https://storage.histreet-app.com/flags/i24/ss.png",
        "val": "+211",
        "DefaultRanking": 204,
        "TAXName": "VAT"
    },
    {
        "ID":   "Sri Lanka",
        "Name": "Sri Lanka",
        "Search": "Sri Lanka",
        "CurrencyName": "Sri Lankan rupee",
        "CurrencySymbol": "Rs",
        "CurrencyNameAbbreviated": "LKR",
        "Flag": "https://storage.histreet-app.com/flags/i24/lk.png",
        "val": "+94",
        "DefaultRanking": 205,
        "TAXName": "VAT"
    },
    {
        "ID":   "Sudan",
        "Name": "Sudan",
        "Search": "Sudan",
        "CurrencyName": "Sudanese pound",
        "CurrencySymbol": "LS",
        "CurrencyNameAbbreviated": "SDG",
        "Flag": "https://storage.histreet-app.com/flags/i24/sd.png",
        "val": "+249",
        "DefaultRanking": 206,
        "TAXName": "VAT"
    },
    {
        "ID":   "Suriname",
        "Name": "Suriname",
        "Search": "Suriname",
        "CurrencyName": "Surinamese dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "SRD",
        "Flag": "https://storage.histreet-app.com/flags/i24/sr.png",
        "val": "+597",
        "DefaultRanking": 207,
        "TAXName": "VAT"
    },
    {
        "ID":   "Sweden",
        "Name": "Sweden",
        "Search": "Sweden",
        "CurrencyName": "Swedish krona",
        "CurrencySymbol": "kr",
        "CurrencyNameAbbreviated": "SEK",
        "Flag": "https://storage.histreet-app.com/flags/i24/se.png",
        "val": "+46",
        "DefaultRanking": 208,
        "TAXName": "VAT"
    },
    {
        "ID":   "Switzerland",
        "Name": "Switzerland",
        "Search": "Switzerland",
        "CurrencyName": "Swiss franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "CHF",
        "Flag": "https://storage.histreet-app.com/flags/i24/ch.png",
        "val": "+41",
        "DefaultRanking": 209,
        "TAXName": "VAT"
    },
    {
        "ID":   "Syria",
        "Name": "Syria",
        "Search": "Syria",
        "CurrencyName": "Syrian pound",
        "CurrencySymbol": "LS",
        "CurrencyNameAbbreviated": "SYP",
        "Flag": "https://storage.histreet-app.com/flags/i24/sy.png",
        "val": "+963",
        "DefaultRanking": 210,
        "TAXName": "VAT"
    },
    {
        "ID":   "Taiwan",
        "Name": "Taiwan",
        "Search": "Taiwan",
        "CurrencyName": "New Taiwan dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "TWD",
        "Flag": "https://storage.histreet-app.com/flags/i24/tw.png",
        "val": "+886",
        "DefaultRanking": 211,
        "TAXName": "VAT"
    },
    {
        "ID":   "Tajikistan",
        "Name": "Tajikistan",
        "Search": "Tajikistan",
        "CurrencyName": "Tajikistani somoni",
        "CurrencySymbol": "SM",
        "CurrencyNameAbbreviated": "TJS",
        "Flag": "https://storage.histreet-app.com/flags/i24/tj.png",
        "val": "+992",
        "DefaultRanking": 212,
        "TAXName": "VAT"
    },
    {
        "ID":   "Tanzania",
        "Name": "Tanzania",
        "Search": "Tanzania",
        "CurrencyName": "Tanzanian shilling",
        "CurrencySymbol": "Shs",
        "CurrencyNameAbbreviated": "TZS",
        "Flag": "https://storage.histreet-app.com/flags/i24/tz.png",
        "val": "+255",
        "DefaultRanking": 213,
        "TAXName": "VAT"
    },
    {
        "ID":   "Thailand",
        "Name": "Thailand",
        "Search": "Thailand",
        "CurrencyName": "Thai baht",
        "CurrencySymbol": "฿",
        "CurrencyNameAbbreviated": "THB",
        "Flag": "https://storage.histreet-app.com/flags/i24/th.png",
        "val": "+66",
        "DefaultRanking": 214,
        "TAXName": "VAT"
    },
    {
        "ID":   "Togo",
        "Name": "Togo",
        "Search": "Togo",
        "CurrencyName": "West African CFA franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "XOF",
        "Flag": "https://storage.histreet-app.com/flags/i24/tg.png",
        "val": "+228",
        "DefaultRanking": 215,
        "TAXName": "VAT"
    },
    {
        "ID":   "Tonga",
        "Name": "Tonga",
        "Search": "Tonga",
        "CurrencyName": "Tongan paʻanga",
        "CurrencySymbol": "T$",
        "CurrencyNameAbbreviated": "TOP",
        "Flag": "https://storage.histreet-app.com/flags/i24/to.png",
        "val": "+676",
        "DefaultRanking": 216,
        "TAXName": "VAT"
    },
    {
        "ID":   "Transnistria",
        "Name": "Transnistria",
        "Search": "Transnistria",
        "CurrencyName": "Transnistrian ruble",
        "CurrencySymbol": "р",
        "CurrencyNameAbbreviated": "RUP",
        
        "val": "+373",
        "DefaultRanking": 217,
        "TAXName": "VAT"
    },
    {
        "ID":   "Trinidad and Tobago",
        "Name": "Trinidad and Tobago",
        "Search": "Trinidad and Tobago",
        "CurrencyName": "Trinidad and Tobago dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "TTD",
        "Flag": "https://storage.histreet-app.com/flags/i24/tt.png",
        "val": "+1",
        "DefaultRanking": 218,
        "TAXName": "VAT"
    },
    {
        "ID":   "Tunisia",
        "Name": "Tunisia",
        "Search": "Tunisia",
        "CurrencyName": "Tunisian dinar",
        "CurrencySymbol": "DT",
        "CurrencyNameAbbreviated": "TND",
        "Flag": "https://storage.histreet-app.com/flags/i24/tn.png",
        "val": "+216",
        "DefaultRanking": 219,
        "TAXName": "VAT"
    },
    {
        "ID":   "Turkey",
        "Name": "Turkey",
        "Search": "Turkey",
        "CurrencyName": "Turkish lira",
        "CurrencySymbol": "₺",
        "CurrencyNameAbbreviated": "TRY",
        "Flag": "https://storage.histreet-app.com/flags/i24/tr.png",
        "val": "+90",
        "DefaultRanking": 220,
        "TAXName": "VAT"
    },
    {
        "ID":   "Turkmenistan",
        "Name": "Turkmenistan",
        "Search": "Turkmenistan",
        "CurrencyName": "Turkmenistani manat",
        "CurrencySymbol": "m",
        "CurrencyNameAbbreviated": "TMT",
        "Flag": "https://storage.histreet-app.com/flags/i24/tm.png",
        "val": "+993",
        "DefaultRanking": 221,
        "TAXName": "VAT"
    },
    {
        "ID":   "Turks and Caicos Islands",
        "Name": "Turks and Caicos Islands",
        "Search": "Turks and Caicos Islands",
        "CurrencyName": "United States dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "USD",
        "Flag": "https://storage.histreet-app.com/flags/i24/tc.png",
        "val": "+1",
        "DefaultRanking": 222,
        "TAXName": "VAT"
    },
    {
        "ID":   "Tuvalu",
        "Name": "Tuvalu",
        "Search": "Tuvalu",
        "CurrencyName": "Australian dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "AUD",
        "Flag": "https://storage.histreet-app.com/flags/i24/tv.png",
        "val": "+688",
        "DefaultRanking": 223,
        "TAXName": "VAT"
    },
    {
        "ID":   "Uganda",
        "Name": "Uganda",
        "Search": "Uganda",
        "CurrencyName": "Ugandan shilling",
        "CurrencySymbol": "Shs",
        "CurrencyNameAbbreviated": "UGX",
        "Flag": "https://storage.histreet-app.com/flags/i24/ug.png",
        "val": "+256",
        "DefaultRanking": 224,
        "TAXName": "VAT"
    },
    {
        "ID":   "Ukraine",
        "Name": "Ukraine",
        "Search": "Ukraine",
        "CurrencyName": "Ukrainian hryvnia",
        "CurrencySymbol": "₴",
        "CurrencyNameAbbreviated": "UAH",
        "Flag": "https://storage.histreet-app.com/flags/i24/ua.png",
        "val": "+380",
        "DefaultRanking": 225,
        "TAXName": "VAT"
    },
    {
        "ID":   "United Arab Emirates",
        "Name": "United Arab Emirates",
        "Search": "United Arab Emirates",
        "CurrencyName": "United Arab Emirates dirham",
        "CurrencySymbol": "Dhs",
        "CurrencyNameAbbreviated": "AED",
        "Flag": "https://storage.histreet-app.com/flags/i24/ae.png",
        "val": "+971",
        "DefaultRanking": 226,
        "TAXName": "VAT"
    },
    {
        "ID":   "Uruguay",
        "Name": "Uruguay",
        "Search": "Uruguay",
        "CurrencyName": "Uruguayan peso",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "UYU",
        "Flag": "https://storage.histreet-app.com/flags/i24/uy.png",
        "val": "+598",
        "DefaultRanking": 227,
        "TAXName": "VAT"
    },
    {
        "ID":   "Uzbekistan",
        "Name": "Uzbekistan",
        "Search": "Uzbekistan",
        "CurrencyName": "Uzbekistani soum",
        "CurrencySymbol": "soum",
        "CurrencyNameAbbreviated": "UZS",
        "Flag": "https://storage.histreet-app.com/flags/i24/uz.png",
        "val": "+998",
        "DefaultRanking": 228,
        "TAXName": "VAT"
    },
    {
        "ID":   "Vanuatu",
        "Name": "Vanuatu",
        "Search": "Vanuatu",
        "CurrencyName": "Vanuatu vatu",
        "CurrencySymbol": "VT",
        "CurrencyNameAbbreviated": "VUV",
        "Flag": "https://storage.histreet-app.com/flags/i24/vu.png",
        "val": "+678",
        "DefaultRanking": 229,
        "TAXName": "VAT"
    },
    {
        "ID":   "Vatican City",
        "Name": "Vatican City",
        "Search": "Vatican City",
        "CurrencyName": "Euro",
        "CurrencySymbol": "€",
        "CurrencyNameAbbreviated": "EUR",
        "Flag": "https://storage.histreet-app.com/flags/i24/va.png",
        "val": "+379",
        "DefaultRanking": 230,
        "TAXName": "VAT"
    },
    {
        "ID":   "Venezuela",
        "Name": "Venezuela",
        "Search": "Venezuela",
        "CurrencyName": "Venezuelan digital bolívar",
        "CurrencySymbol": "Bs.D",
        "CurrencyNameAbbreviated": "VED",
        "Flag": "https://storage.histreet-app.com/flags/i24/ve.png",
        "val": "+58",
        "DefaultRanking": 231,
        "TAXName": "VAT"
    },
    {
        "ID":   "Vietnam",
        "Name": "Vietnam",
        "Search": "Vietnam",
        "CurrencyName": "Vietnamese đồng",
        "CurrencySymbol": "₫",
        "CurrencyNameAbbreviated": "VND",
        "Flag": "https://storage.histreet-app.com/flags/i24/vn.png",
        "val": "+84",
        "DefaultRanking": 232,
        "TAXName": "VAT"
    },
    {
        "ID":   "Wallis and Futuna",
        "Name": "Wallis and Futuna",
        "Search": "Wallis and Futuna",
        "CurrencyName": "CFP franc",
        "CurrencySymbol": "Fr",
        "CurrencyNameAbbreviated": "XPF",
        "Flag": "https://storage.histreet-app.com/flags/i24/wf.png",
        "val": "+681",
        "DefaultRanking": 233,
        "TAXName": "VAT"
    },
    {
        "ID":   "Yemen",
        "Name": "Yemen",
        "Search": "Yemen",
        "CurrencyName": "Yemeni rial",
        "CurrencySymbol": "Rls",
        "CurrencyNameAbbreviated": "YER",
        "Flag": "https://storage.histreet-app.com/flags/i24/ye.png",
        "val": "+967",
        "DefaultRanking": 234,
        "TAXName": "VAT"
    },
    {
        "ID":   "Zambia",
        "Name": "Zambia",
        "Search": "Zambia",
        "CurrencyName": "Zambian kwacha",
        "CurrencySymbol": "K",
        "CurrencyNameAbbreviated": "ZMW",
        "Flag": "https://storage.histreet-app.com/flags/i24/zm.png",
        "val": "+260",
        "DefaultRanking": 235,
        "TAXName": "VAT"
    },
    {
        "ID":   "Zimbabwe",
        "Name": "Zimbabwe",
        "Search": "Zimbabwe",
        "CurrencyName": "Zimbabwean dollar",
        "CurrencySymbol": "$",
        "CurrencyNameAbbreviated": "ZWL",
        "Flag": "https://storage.histreet-app.com/flags/i24/zw.png",
        "val": "+263",
        "DefaultRanking": 236,
        "TAXName": "VAT"
    }
]
export {countryCodeList}