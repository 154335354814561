import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils,faMapSigns, faBox, faPhone, faLocation, faMapMarked } from '@fortawesome/free-solid-svg-icons'

// import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";

export default function CustomerSlider() {
  return (
    <div>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        showsPagination={false}
        loop={true}
        autoplay={{
            delay: 2500,
          }}
        // pagination={{
        //   clickable: true,
        // }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
            <div className="flex md:h-auto flex-col lg:flex-row text-left m-0 sm:m-8 lg:m-16 pb-8 lg:pb-0 drop-shadow-2xl  bg-white border border-white-600  transition ease-in-out delay-50'">
                <div className="flex flex-row w-full lg:w-80 h-48 md:h-[30rem] mb-4 lg:mb-0 justify-center">    
                    <img className="object-cover" src={require('../images/tfc.jpg')} alt="..." />
                </div>
                <div className="mx-16 flex flex-col align-center justify-center flex-1">
                  <div className="flex flex-row">
                      <div className="flex flex-1 flex-col">
                        <div className="font-bold text-base md:text-2xl">MR. MUSFIQUR RAHMAN</div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-base md:text-2xl">Owner</div>
                      </div>
                    </div>    
                  <div className="text-sm md:text-base lg:text-lg my-8 text-left">
                      “Our restaurant struggled to manage online orders due to an outdated clunky system, since switching to QuickFood our online sales have increased by 25%, and customer satisfaction has skyrocketed.”
                  </div>
                  <div className="flex flex-row">
                    <div className="flex flex-1 flex-col">
                      <div className="font-bold text-base md:text-2xl">Tiffins</div>
                      <div className="flex flex-row my-1">
                        <FontAwesomeIcon className="block mr-2" icon={faMapMarked} color="#aaa" size="lg"/>
                        <div className="text-sm md:text-xl">Abingdon,UK</div>
                      </div>
                      <div className="flex flex-row my-1">
                        <FontAwesomeIcon className="block mr-2" icon={faPhone} color="#aaa" size="lg"/>
                        <div className="text-sm md:text-xl">01235 537786</div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <img className="w-16 h-16 md:w-24 md:h-24" src={require('../images/tiffins.jpg')} alt="..." />
                    </div>
                  </div>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="flex md:h-auto flex-col lg:flex-row text-left m-0 sm:m-8 lg:m-16 pb-8 lg:pb-0 drop-shadow-2xl  bg-white border border-white-600  transition ease-in-out delay-50'">
                <div className="flex flex-row w-full lg:w-80 h-48 md:h-[30rem] mb-4 lg:mb-0 justify-center"> 
                    <img className="object-cover" src={require('../images/mjc.jpg')} alt="..." />
                </div>
                <div className="mx-16 flex flex-col align-center justify-center flex-1">
                  <div className="flex flex-row">
                      <div className="flex flex-1 flex-col">
                        <div className="font-bold text-base md:text-2xl">J.W JAMES</div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-base md:text-2xl">OWNER, MD</div>
                      </div>
                    </div>    
                  <div className="text-sm md:text-base lg:text-lg my-8 text-left">
                      “As a small family owned restaurant we were scared about the cost and time when switching to a new website and app, but Neil reassured us, and I'm so glad he did as the integrated payment system has been an absolute game changer.”
                  </div>
                  <div className="flex flex-row">
                    <div className="flex flex-1 flex-col">
                      <div className="font-bold text-base md:text-2xl">Majliss</div>
                      <div className="flex flex-row my-1">
                        <FontAwesomeIcon className="block mr-2" icon={faMapMarked} color="#aaa" size="lg"/>
                        <div className="text-sm md:text-xl">Oxford, UK</div>
                      </div>
                      <div className="flex flex-row my-1">
                        <FontAwesomeIcon className="block mr-2" icon={faPhone} color="#aaa" size="lg"/>
                        <div className="text-sm md:text-xl">01865 726728</div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <img className="w-16 h-16 md:w-24 md:h-24" src={require('../images/Majliss.png')} alt="..." />
                    </div>
                  </div>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="flex md:h-auto flex-col lg:flex-row text-left m-0 sm:m-8 lg:m-16 pb-8 lg:pb-0 drop-shadow-2xl  bg-white border border-white-600  transition ease-in-out delay-50'">
                <div className="flex flex-row w-full lg:w-80 h-48 md:h-[30rem] mb-4 lg:mb-0 justify-center">    
                    <img className="object-cover" src={require('../images/nc.jpg')} alt="..." />
                </div>
                <div className="mx-16 flex flex-col align-center justify-center flex-1">
                  <div className="flex flex-row">
                      <div className="flex flex-1 flex-col">
                        <div className="font-bold text-base md:text-2xl">MR. SHUMON ALI</div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-base md:text-2xl">Owner</div>
                      </div>
                    </div>    
                  <div className="text-sm md:text-base lg:text-lg my-8 text-left">
                      “Commissions to JustEat, Deliveroo and UberEats were becoming our biggest cost. As a popular, high volume restaurant, we needed a cost-effective and reliable solution. Within a week of partnering within QuickFood, our sales had increased 23% and our costs were down 27%.”
                  </div>
                  <div className="flex flex-row">
                    <div className="flex flex-1 flex-col">
                      <div className="font-bold text-base md:text-2xl">Nandon</div>
                      <div className="flex flex-row my-1">
                        <FontAwesomeIcon className="block mr-2" icon={faMapMarked} color="#aaa" size="lg"/>
                        <div className="text-sm md:text-xl">Ivybridge, UK</div>
                      </div>
                      <div className="flex flex-row my-1">
                        <FontAwesomeIcon className="block mr-2" icon={faPhone} color="#aaa" size="lg"/>
                        <div className="text-sm md:text-xl">01752 892726</div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <img className="w-16 h-16 md:w-24 md:h-24" src={require('../images/Nandon.png')} alt="..." />
                    </div>
                  </div>
                </div>
            </div>
        </SwiperSlide>

      </Swiper>
    </div>
  );
}
