import './privacy.css'
export function Privacy() {
    return (

        <div class="c29 doc-content">
            <h2 class="c0" id="h.t2uof9yhjw5m"><span class="c4 c43">Privacy promise for end users</span></h2>
            <p class="c0"><span class="c3">In this privacy promise, you can learn more about how QuickFood (QuickFood) processes
                your personal data when you use our online order website and mobile application, depending on the platform
                you use (the Service).</span></p>
            <p class="c0"><span class="c3">We provide you with this information as we are required to do so under the UK General
                Data Protection Regulation (UK GDPR).</span></p>
            <h3 class="c0" id="h.jrrki35yese6"><span class="c10 c4">Controller</span></h3>
            <p class="c0"><span class="c27">QuickFood</span><span class="c3">&nbsp;is the controller in respect of the
                processing of your personal data when you create a user profile and/or when you complete an order on the
                Service.</span></p>
            <p class="c0"><span class="c3">QuickFood&#39;s contact details are stated at the bottom of this webpage.</span></p>
            <h3 class="c0" id="h.kxm49qf0rf01"><span class="c10 c4">Purposes of and legal basis for processing</span></h3>
            <p class="c0"><span class="c3">When you use the Service, we process your personal data for several purposes. In the
                table below, you can learn more about the various purposes, which data we process and what the legal basis
                for our processing is.</span></p><a id="t.0461bbe4ef912b3e8613a4086e9e9fe90b285ebf"></a><a id="t.0"></a>
            <table class="c35">
                <tr class="c17">
                    <td class="c26" colspan="1" rowspan="1">
                        <p class="c16"><span class="c4">Processing activities</span></p>
                    </td>
                    <td class="c30" colspan="1" rowspan="1">
                        <p class="c16"><span class="c4">Purpose(s) of the processing</span></p>
                    </td>
                    <td class="c18" colspan="1" rowspan="1">
                        <p class="c16"><span class="c4">Legal basis for the processing</span></p>
                    </td>
                </tr>
                <tr class="c44">
                    <td class="c45" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">Processing your orders and creating your user profile</span></p>
                    </td>
                    <td class="c23" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">We process your contact details (name, address, email address and
                            telephone number) and particular order data (any comments on the order, purchase history,
                            payment details, etc.) for the purpose of registering and completing payment of your order which
                            we share with the relevant restaurant and for the purpose of relocating the details in
                            connection with future orders. In addition, we process your contact details to be able to create
                            your user profile, if relevant.</span></p>
                    </td>
                    <td class="c20" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">The legal basis for our processing is article 6(1)(f) of the UK General
                            Data Protection Regulation (UK GDPR) as we are pursuing our legitimate interest in collecting
                            payments for our and the restaurant&#39;s services, letting the restaurant know which order to
                            prepare and deliver, and creating a profile for you.</span></p>
                    </td>
                </tr>
                <tr class="c46">
                    <td class="c8" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">Checking and correcting errors in the Service</span></p>
                    </td>
                    <td class="c7" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">We record log data in the Service, and such log data may include personal
                            data (e.g. your IP address). The log data is recorded for the purpose of correcting technical
                            errors in the Service and identifying and preventing potential fraud (e.g. in case of unusual
                            orders etc.).</span></p>
                    </td>
                    <td class="c19" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">The legal basis for our processing is article 6(1)(f) of the UK General
                            Data Protection Regulation (UK GDPR) as we are pursuing our legitimate interest in correcting
                            errors and improving the Service, including making it more user-friendly.</span></p>
                    </td>
                </tr>
                <tr class="c38">
                    <td class="c8" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">Answering and processing enquiries about the Service</span></p>
                    </td>
                    <td class="c7" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">When you contact us (e.g. by email or directly on the Service), your
                            enquiry will often contain personal data, including your contact details and other personal data
                            which you may disclose to us. We process such data to be able to process and answer your
                            enquiries and provide general customer service and technical support etc. relating to your use
                            of the Service.</span></p>
                    </td>
                    <td class="c19" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">The legal basis for our processing is article 6(1)(f) of the UK General
                            Data Protection Regulation (UK GDPR) as we are pursuing our legitimate interest in providing
                            relevant customer service and/or technical support to you when you use the Service.</span></p>
                    </td>
                </tr>
            </table>
            <h3 class="c0" id="h.k5lutjxru922"><span class="c10 c4">Recipients of your personal data</span></h3>
            <h4 class="c0" id="h.kfma59j96jlq"><span class="c4 c14">Disclosure to external third parties</span></h4>
            <p class="c0"><span class="c3">We disclose your name, email address and order data to the restaurant preparing your
                order, in particular for the purpose of enabling the restaurant to send service notices about the delivery
                of your order. The restaurant also receives your name and your email address for the purpose of distributing
                marketing material (if you have consented to receive electronic marketing from the restaurant).</span></p>
            <p class="c0"><span class="c3">We may also disclose your personal data to our external business partners (e.g.
                carriers or couriers who may deliver the items you have ordered from the restaurant on the Service). We also
                disclose personal data included in our accounting records (e.g. your contact and/or payment details) to the
                relevant public authorities, including the tax and customs authorities, in connection with our statutory
                bookkeeping etc.</span></p>
            <h4 class="c0" id="h.sc9fjtsx3u81"><span class="c14 c4">Disclosure within the QuickFood group</span></h4>
            <p class="c0"><span class="c3">For the purpose of providing efficient and stable customer service irrespective of
                the country you are in, we share your personal data with the other QuickFood group companies. That also
                means that we may have received personal data about you from one of our group companies, e.g. for the
                purpose of processing your enquiries relating to particular orders or your use of the Service.</span></p>
            <h3 class="c0" id="h.9kmv791qdac"><span class="c10 c4">Use of processors</span></h3>
            <p class="c0"><span class="c3">We make your personal data available to our processors who e.g. host, develop and
                support the QuickFood group&#39;s IT systems and/or send service notices for us.</span></p>
            <h3 class="c0" id="h.4x6a8a2tlnxf"><span class="c10 c4">Transfer of your personal data to third countries</span>
            </h3>
            <p class="c0"><span class="c3">When we make data available to our processors, we may transfer your personal data to
                the USA, Ukraine, and the Philippines. The basis for such transfer is the Commission Decision of 5 February
                2010 on standard contractual clauses for the transfer of personal data to processors established in third
                countries under Directive 95/46/EC of the European Parliament and of the Council as we have entered into
                such standard contractual clauses with the relevant recipients of the data.</span></p>
            <h3 class="c0" id="h.u5d876d4hnev"><span class="c4 c10">Storage of your personal data</span></h3>
            <p class="c0"><span class="c3">We are required to only store your personal data for the period necessary for us to
                fulfil the purposes for which they were collected. For that reason, we have established the time limits for
                erasure set out in the table below. As a general rule, we erase or anonymise your personal data according to
                the time limits stated below unless it is necessary that we continue to store them, e.g. for the purpose of
                particular cases or the like.</span></p>
            <p class="c0"><span class="c3">When it is stated below that your personal data will be erased, we may instead
                anonymise the data, and then the data can no longer be used to identify you. Anonymised data are not covered
                by the UK GDPR as they are no longer personal data.</span></p><a
                    id="t.fc79f1bd521392476008107e4e41b08e212a00db"></a><a id="t.1"></a>
            <table class="c35">
                <tr class="c17">
                    <td class="c31" colspan="1" rowspan="1">
                        <p class="c16"><span class="c4">Processing activities</span></p>
                    </td>
                    <td class="c21" colspan="1" rowspan="1">
                        <p class="c16"><span class="c4">Storage and time limits for erasure</span></p>
                    </td>
                </tr>
                <tr class="c34">
                    <td class="c40" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">Processing your orders and creating your user profile</span></p>
                    </td>
                    <td class="c28" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">As a general rule, data relating to particular orders or other agreements
                            with you are stored for 5 years after the end of the financial year in which the order was
                            completed or the agreement has ended.</span></p>
                        <p class="c1"><span class="c3">The personal data are stored e.g. for the purpose of complying with our
                            obligation to present financial statements etc.</span></p>
                        <p class="c1"><span class="c3">As a general rule, data included in your user profile but not related to
                            a particular order (e.g. your login information etc.) are stored for 12 months after the end of
                            the financial year in which you were last logged in (unless you have deactivated your user
                            profile before that).</span></p>
                    </td>
                </tr>
                <tr class="c2">
                    <td class="c42" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">Checking and correcting errors in the Service</span></p>
                    </td>
                    <td class="c9" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">As a general rule, the personal data included in the recorded log data
                            are erased no later than 12 months after the particular actions on the Service are
                            recorded.</span></p>
                    </td>
                </tr>
                <tr class="c46">
                    <td class="c42" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">Answering and processing enquiries about the Service</span></p>
                    </td>
                    <td class="c9" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">As a general rule, data relating to particular cases or agreements with
                            you, or the business you represent, are stored for 5 years after the end of the financial year
                            in which your last enquiry was closed or the agreement has ended.</span></p>
                        <p class="c1"><span class="c3">The personal data are stored e.g. for the purpose of complying with our
                            obligation to present financial statements etc.</span></p>
                        <p class="c1"><span class="c3">As a general rule, we erase data not related to a particular case or
                            agreement 12 months after the end of the financial year in which your last enquiry was
                            closed.</span></p>
                    </td>
                </tr>
            </table>
            <h3 class="c0" id="h.zg9i6si2t1f6"><span class="c10 c4">Your rights etc.</span></h3>
            <p class="c0"><span class="c3">You have special rights to help you control your personal data, and we wish to make
                it easy for you to exercise those rights:</span></p><a
                    id="t.3f865bcab0686068eec0e4f1d67a22787f8a6b34"></a><a id="t.2"></a>
            <table class="c35">
                <tr class="c17">
                    <td class="c13" colspan="1" rowspan="1">
                        <p class="c16"><span class="c4">Rights</span></p>
                    </td>
                    <td class="c15" colspan="1" rowspan="1">
                        <p class="c16"><span class="c4">Descriptions</span></p>
                    </td>
                </tr>
                <tr class="c33">
                    <td class="c41" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">Right of access</span></p>
                    </td>
                    <td class="c24" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">If you ask us, we will confirm whether we process your personal data and,
                            if so, we will give you a copy of them.</span></p>
                    </td>
                </tr>
                <tr class="c32">
                    <td class="c6" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">Right to rectification</span></p>
                    </td>
                    <td class="c5" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">If your personal data are inaccurate or incomplete, you have a right to
                            ask us to correct or complete them.</span></p>
                    </td>
                </tr>
                <tr class="c34">
                    <td class="c6" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">Right to erasure</span></p>
                    </td>
                    <td class="c5" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">You have the right to ask us to erase the data in specific circumstances,
                            such as:</span></p>
                        <p class="c1"><span class="c3">- Where the personal data is no longer necessary for the purpose it was
                            originally collected/processed.</span></p>
                        <p class="c1"><span class="c3">- If you have withdrawn your consent.</span></p>
                        <p class="c1"><span class="c3">- If you have objected to the processing, and there is no overriding
                            legitimate interest for us to continue with the processing.</span></p>
                        <p class="c1"><span class="c3">- If you have objected to the processing of your data for direct
                            marketing purposes.</span></p>
                        <p class="c1"><span class="c3">- If your personal data was unlawfully processed.</span></p>
                        <p class="c1"><span class="c3">- If we have a legal obligation to erase your data.</span></p>
                    </td>
                </tr>
                <tr class="c32">
                    <td class="c6" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">Right to restriction of processing</span></p>
                    </td>
                    <td class="c5" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">In certain circumstances, you have the right to ask us to restrict our
                            use of the data we process about you.</span></p>
                    </td>
                </tr>
                <tr class="c2">
                    <td class="c6" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">Right to data portability</span></p>
                    </td>
                    <td class="c5" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">You have the right to receive the personal data that we process about you
                            in a structured, commonly used and machine-readable format and to use it elsewhere.</span></p>
                    </td>
                </tr>
                <tr class="c2">
                    <td class="c6" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">Right to object and withdrawal of consent</span></p>
                    </td>
                    <td class="c5" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">In certain circumstances, you have the right to object to our otherwise
                            lawful processing of your personal data, including processing for direct marketing
                            purposes.</span></p>
                    </td>
                </tr>
                <tr class="c2">
                    <td class="c6" colspan="1" rowspan="1">
                        <p class="c1"><span class="c3">Right of complaint</span></p>
                    </td>
                    <td class="c5" colspan="1" rowspan="1">
                        <p class="c1"><span class="c27">If you wish to complain about our processing of your personal data, you
                            may file a complaint with the Information Commissioner&#39;s Office (ICO) on </span><span
                                class="c37"><a class="c39"
                                    href="https://www.google.com/url?q=https://ico.org.uk/&amp;sa=D&amp;source=editors&amp;ust=1679324770150886&amp;usg=AOvVaw0fvAqS0yWKvBKmsI_f6wNX">https://ico.org.uk/</a></span>
                        </p>
                    </td>
                </tr>
            </table>
            <p class="c0"><span class="c3">In order to exercise your rights described here, you can contact us directly using
                the contact details stated at the bottom of this webpage.</span></p>
            <h4 class="c0" id="h.rz8yhk2vwt1w"><span class="c14 c4">Right to withdraw consent (unsubscribing from
                marketing)</span></h4>
            <p class="c0"><span class="c3">If you are no longer interested in receiving news or offers from QuickFood, you may
                withdraw your consent by unsubscribing from marketing in the individual emails you receive. You may also
                contact us directly using the contact details stated at the bottom of this webpage.</span></p>
            <p class="c0"><span class="c3">If you withdraw your consent, it will not affect the lawfulness of our processing
                before the withdrawal. As a result, your withdrawal will only be effective in respect of future
                processing.</span></p>
            <h3 class="c0" id="h.garpfoi654ts"><span class="c10 c4">Contact</span></h3>
            <p class="c0"><span class="c3">If you have any questions about how we process personal data, please contact us using
                the following contact details:</span></p>
            <ul class="c36 lst-kix_zgrswlno10cw-0 start">
                <li class="c11 li-bullet-0"><span class="c3">QuickFood Limited</span></li>
                <li class="c11 li-bullet-0"><span class="c3">Company number 14563582</span></li>
                <li class="c11 li-bullet-0"><span class="c3">Unit 20A, Bankside, Station Approach, Kidlington, Oxfordshire, OX5
                    1JE</span></li>
                <li class="c11 li-bullet-0"><span class="c27">Email: </span><span class="c12">Admin@QuickFood.io</span></li>
            </ul>
            <p class="c0"><span class="c3">For the purpose of protecting your data, we may, in certain circumstances, ask you to
                confirm your identity before we are able to process your request. Therefore, it will be practical if you
                contact us by email and include a reference to an order number or the like in your enquiry.</span></p>
            <p class="c16 c25"><span class="c14 c22"></span></p>
        </div>
    )
}