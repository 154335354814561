// import logo from './logo.svg';
import { Route, Routes } from 'react-router-dom'
import { Home } from './pages/Home'
import { Privacy } from './pages/Privacy'
import { Terms } from './pages/Terms'


function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/privacy" element={<Privacy />}></Route>
      <Route path="/privacyPolicy" element={<Privacy />}></Route>
      <Route path="/terms" element={<Terms />}></Route>
    </Routes>
  )
}

export default App;
