import React from "react";
import './values.css'
function Values(props) {
  return (
      <input
        type="range"
        defaultValue={props.value}
        onChange={props.handleChange}
        min={props.min}
        max={props.max}
      />
  );
}

export default Values;
