/**
 * @copyright Elmelo Ltd.
 */

import React, { useState, useRef, useCallback } from 'react'

import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [businessName, setBusinessName] = useState("");
	const [city, setCity] = useState("");
	const [country, setCountry] = useState("");
	const [restaurantType, setRestaurantType] = useState("");
	const [restaurantSize, setRestaurantSize] = useState("");

	const [disableSubmit, setDisableSubmit] = useState( true );

	const form = useRef();

	const FormValidation = () => {
		let validationText = "";
		
		if( !firstName ) validationText = "Please enter your first name";
		else if( !lastName ) validationText = "Please enter your last name";
		else if( !email ) validationText = "Please enter your email";
		else if( !phone ) validationText = "Please enter your phone number";
		else if( !businessName ) validationText = "Please enter your business name";
		else if( !city ) validationText = "Please enter your city";
		else if( !country ) validationText = "Please enter your country";
		else if( !restaurantType ) validationText = "Please select your restaurant type";
		else if( !restaurantSize ) validationText = "Please select your restaurant size";

		if (validationText){
			alert(validationText);
			return false;
		};

		return true;
	}

	const SendEmail = (e) => {
		e.preventDefault();
		try {
			if( !FormValidation() ) return;

			emailjs.sendForm('service_5o64u08', 'template_v9bi9as', form.current, 'ymEMzL1B9os1JFViw')
				.then((result) => {
					console.log(result.text);
					alert( "Your query was submitted. One of our representatives will be in contact shortly." );
					e.target.reset();
				}, (error) => {
					console.error(error.text);
				});
		} catch ( err ) {
			console.error("contactForm : SendEmail : err : ", err);
		};		
	};

	return (
		<div className='flex-1 mr-8 ml-8 lg:mr-16 lg:ml-0'>
			<form ref={form} onSubmit={SendEmail} id={form}>
				<div className='font-semibold mb-4 text-2xl'> Get started today.</div>
				<div className='text-sm'>
					Let us know a little about your business, and we'll put you on the right track.
				</div>
				<label class="block py-4">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						First Name
					</span>
					<input type="text" 
						name="firstName" 
						class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-green-500 focus:ring-green-500 block w-full rounded-md sm:text-sm focus:ring-1" 
						placeholder="First Name"
						onChange={ val => setFirstName( val.target.value ) }
					/>
				</label>
				<label class="block py-4">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Last Name
					</span>
					<input type="text" 
						name="lastName" 
						class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-green-500 focus:ring-green-500 block w-full rounded-md sm:text-sm focus:ring-1" 
						placeholder="Last Name"
						onChange={ val => setLastName( val.target.value ) } 
					/>
				</label>
				<label class="block py-4">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Email
					</span>
					<input type="text" 
						name="email" 
						class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-green-500 focus:ring-green-500 block w-full rounded-md sm:text-sm focus:ring-1" 
						placeholder="Email"
						onChange={ val => setEmail( val.target.value ) }
					/>
				</label>
				<label class="block py-4">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Mobile
					</span>
					<input type="text" 
						name="mobile" 
						class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-green-500 focus:ring-green-500 block w-full rounded-md sm:text-sm focus:ring-1" 
						placeholder="Mobile" 
						onChange={ val => setPhone( val.target.value ) }
					/>
				</label>
				<label class="block py-4">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Business Name
					</span>
					<input type="text" 
						name="businessName" 
						class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-green-500 focus:ring-green-500 block w-full rounded-md sm:text-sm focus:ring-1" 
						placeholder="Business Name" 
						onChange={ val => setBusinessName( val.target.value ) }
					/>
				</label>
				<label class="block py-4">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						City/Town
					</span>
					<input type="text" 
						name="city" 
						class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-green-500 focus:ring-green-500 block w-full rounded-md sm:text-sm focus:ring-1" 
						placeholder="City/Town" 
						onChange={ val => setCity( val.target.value ) }
					/>
				</label>
				<label class="block py-4">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Region/Country
					</span>
					<input type="text" 
						name="country"
						class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-green-500 focus:ring-green-500 block w-full rounded-md sm:text-sm focus:ring-1" 
						placeholder="Region/Country" 
						onChange={ val => setCountry( val.target.value ) }
					/>
				</label>
				<label class="block py-4">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						Select which one best describes you
					</span>
					<select 
						name="restaurant_type" 
						id="restaurant_type" 
						class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-green-500 focus:ring-green-500 block w-full rounded-md sm:text-sm focus:ring-1"
						onChange={ val => setRestaurantType(val.target.value) }
					>
						<option value="">Please select</option>
						<option name="type_r" value="restaurant">Restaurants</option>
						<option name="type_t" value="takeaways">Takeaways</option>
						<option name="type_fc" value="fastCasual">Fast Casual</option>
						<option name="type_qc" value="quickService">Quick Service Restaurant</option>
						<option name="type_c" value="cafe">Cafe</option>
						<option name="type_o" value="other">Other</option>
					</select>
				</label>
				<label class="block py-4">
					<span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						How many outlets/stores does your business have at present?
					</span>
					<select 
						name="restaurant_size" 
						id="restaurant_size" 
						class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-green-500 focus:ring-green-500 block w-full rounded-md sm:text-sm focus:ring-1"
						onChange={ val => setRestaurantSize(val.target.value) }
					>
						<option value="">Please select</option>
						<option name="small" value="small">1</option>
						<option name="medium" value="medium">2-4</option>
						<option name="large" value="large">5-10</option>
						<option name="biiiiiig" value="biiiiiig">10+</option>
						<option name="none" value="none">None yet</option>
					</select>
				</label>

				<label class="block py-4">
					<span class="mb-4 after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
						I want to know more about (Select all that applies)
					</span>
					<ul class="text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
						<li class="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
							<div class="flex items-center pl-3">
								<input id="vue-checkbox" type="checkbox" name="website" value="true" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
								<label for="vue-checkbox" class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Ordering Websites</label>
							</div>
						</li>
						<li class="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
							<div class="flex items-center pl-3">
								<input id="react-checkbox" type="checkbox" name="app" value="true" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
								<label for="react-checkbox" class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Ordering Apps</label>
							</div>
						</li>
						<li class="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
							<div class="flex items-center pl-3">
								<input id="angular-checkbox" type="checkbox" name="qr" value="true" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
								<label for="angular-checkbox" class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">QR Code Order & Pay</label>
							</div>
						</li>
						<li class="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
							<div class="flex items-center pl-3">
								<input id="laravel-checkbox" type="checkbox" name="marketing" value="true" class="w-4 h-4 text-green-600 bg-gray-100 rounded border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
								<label for="laravel-checkbox" class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Managed Marketing</label>
							</div>
						</li>
					</ul>
				</label>
				<ReCAPTCHA 
					sitekey="6LePJrMjAAAAAPkLL8ChWQ9lGsC8euXNylVDrC4r" 
					onChange={ useCallback(() => setDisableSubmit(false)) }
				/>
				{/* <div class="g-recaptcha" data-sitekey="6LePJrMjAAAAAPkLL8ChWQ9lGsC8euXNylVDrC4r"></div> */}
				<div>
					{
						!disableSubmit
						?
							<button
								className='flex w-48 border border-white-800 mb-16 px-4 text-left text-xl py-4 mt-8 transition ease-in-out delay-50 bg-gradient-to-r from-green-400 to-green-400 hover:from-green-200 hover:to-green-300 ... hover:drop-shadow-xl  hover:-translate-y-1  hover:scale-110' 
								>
									Submit
							</button>
						:
							<button
								className='flex w-48 border border-white-800 mb-16 px-4 text-left text-xl py-4 mt-8 transition ease-in-out delay-50 bg-gradient-to-r from-grey-400 to-grey-400 hover:from-grey-200 hover:to-grey-300 ... hover:drop-shadow-xl  hover:-translate-y-1  hover:scale-110' 
								type="submit" disabled={disableSubmit}
							>
									Submit
							</button>
					}
				</div>
			</form>		
		</div>
	);
};

export default ContactForm;