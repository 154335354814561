import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils,faMapSigns, faBox } from '@fortawesome/free-solid-svg-icons'

// import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";

export default function SliderCards() {
  return (
    <div>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        showsPagination={false}
        loop={true}
        autoplay={{
            delay: 2500,
          }}
        // pagination={{
        //   clickable: true,
        // }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
            <div className="flex h-[34rem] md:h-auto flex-col lg:flex-row text-left drop-shadow-2xl bg-white border border-white-600 m-0 sm:m-8 lg:m-16  px-8 lg:px-24 py-10 lg:py-32 transition ease-in-out delay-50 hover:-translate-y-1  hover:scale-120'">
                <div className="flex flex-row flex-1 mb-8 flex-1 justify-center">    
                    <img className="h-48 w-5/6 md:w-full md:h-96 object-contain" src={require('../images/8500.jpg')} alt="..." />
                </div>
                <div className="mx-8 flex flex-col align-center justify-center flex-1">    
                    <a class="underline decoration-green-500 font-medium text-2xl mb-4">Loyalty and marketing</a>
                    <div className="font-semibold text-lg md:text-3xl">The ingredients to grow your business</div>
                    <div className="text-sm md:text-base lg:text-lg my-8 text-left">Take over the globe while building your brand with us. QuickFood provides you with the insight, the tools and the data to accomplish your goals.</div>
                    {/* <div className='w-48 border border-white-800 px-4 text-left text-xl py-4 transition ease-in-out delay-50 bg-gradient-to-r from-green-400 to-green-400 hover:from-green-200 hover:to-green-300 ... hover:drop-shadow-xl  hover:-translate-y-1  hover:scale-110'>
                        Learn More
                    </div> */}
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="flex h-[34rem] md:h-auto flex-col lg:flex-row text-left drop-shadow-2xl bg-white border border-white-600 m-0 sm:m-8 lg:m-16  px-8 lg:px-24 py-10 lg:py-32 transition ease-in-out delay-50 hover:-translate-y-1  hover:scale-120'">
                <div className="flex flex-row mb-8 flex-1 justify-center">    
                    <img className="h-48 w-5/6 md:w-full md:h-96 object-contain" src={require('../images/qr.png')} alt="..." />
                </div>
                <div className="mx-8 flex flex-col align-center justify-center flex-1">    
                    <a class="underline decoration-green-500 font-medium text-2xl mb-4">Ordering in-store</a>
                    <div className="font-semibold text-lg md:text-3xl">Order using QR codes and kiosks, convenient, safe and fast.</div>
                    <div className="text-sm md:text-base lg:text-lg my-8 text-left">The QuickFood portal is directly integrated with all the tools to increase customer satisfaction, increase average order value and reduce customer queue-times.</div>
                    {/* <div className='w-48 border border-white-800 px-4 text-left text-xl py-4 transition ease-in-out delay-50 bg-gradient-to-r from-green-400 to-green-400 hover:from-green-200 hover:to-green-300 ... hover:drop-shadow-xl  hover:-translate-y-1  hover:scale-110'>
                        Learn More
                    </div> */}
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="flex h-[34rem] md:h-auto flex-col lg:flex-row text-left drop-shadow-2xl bg-white border border-white-600 m-0 sm:m-8 lg:m-16 px-8 lg:px-24 py-10 lg:py-32 transition ease-in-out delay-50 hover:-translate-y-1  hover:scale-120'">
                <div className="flex flex-row mb-8 flex-1 justify-center">    
                    <img className=" h-48  w-5/6 md:w-full md:h-96 object-contain" src={require('../images/order.jpg')} alt="..." />
                </div>
                <div className="mx-8 flex flex-col align-center justify-center flex-1">    
                    <a class="underline decoration-green-500 font-medium text-2xl mb-4">Ordering online</a>
                    <div className="font-semibold text-lg md:text-3xl">Ordering online - simple, intuitive and quick</div>
                    <div className="text-sm md:text-base lg:text-lg my-8 text-left">Convert those one time visitors into regular, weekly customers by ensuring a lightning-fast experience via QuickFood</div>
                    {/* <div className='w-48 border border-white-800 px-4 text-left text-xl py-4 transition ease-in-out delay-50 bg-gradient-to-r from-green-400 to-green-400 hover:from-green-200 hover:to-green-300 ... hover:drop-shadow-xl  hover:-translate-y-1  hover:scale-110'>
                        Learn More
                    </div> */}
                </div>
            </div>
        </SwiperSlide>

      </Swiper>
    </div>
  );
}
