import './terms.css'
export function Terms() {
    return (
        <div class="c15 doc-content">
            <h2 class="c8 c14" id="h.o4t8odeje5bw"><span class="c13">Terms of use</span></h2>
            <ul class="c1 lst-kix_wv6na3xgpjfx-0 start">
                <li class="c2 li-bullet-0"><span class="c4">INTRODUCTION</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c4"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-1 start">
                <li class="c5 li-bullet-0"><span class="c6">This mobile application or order website (depending on the platform
                    you use) (the &quot;Service&quot;) is made available to you by QuickFood, company number 14563582, Unit
                    20A, Bankside, Station Approach, Kidlington, Oxfordshire, OX5 1JE, (&quot;QuickFood&quot;) and provides
                    you with the possibility of purchasing items from a number of particular caf&eacute;s, restaurants and
                    other takeaways (the &quot;Restaurant&quot;).</span></li>
                <li class="c5 li-bullet-0"><span class="c0">These terms of use (the &quot;Terms of Use&quot;) apply to your use
                    of the Service, including your registration with and order and payment of products on the
                    Service.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">Agent: When you purchase items on the Service, QuickFood acts as an
                    agent on behalf of the Restaurant as QuickFood has the authority to conclude the purchase agreement with
                    you on behalf of the Restaurant. As a result, an agreement is made between you and the Restaurant for
                    the purchase of the Restaurant&#39;s items in your Order, and QuickFood is not a party to that purchase
                    agreement. Moreover, QuickFood has the authority of the Restaurant to receive payment for the Orders you
                    make through the Service, and by paying QuickFood for such Orders you will be treated as making payment
                    to the Restaurant and the Restaurant will not be able to make any further claim against you for
                    payment.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">Conclusion of agreement with the Restaurant: A completed order (each
                    an &quot;Order&quot;) on the Service constitutes a binding offer to the Restaurant for the conclusion of
                    an agreement to deliver the relevant items you ordered. A binding agreement has not been concluded
                    between you and the Restaurant for delivery of the items until the Restaurant has accepted your Order
                    and an order acknowledgement has been sent to you. However, you acknowledge that by clicking on the
                    &quot;FINISH THE ORDER&quot; (or similarly named) button, you enter into an obligation to pay for the
                    item(s) in your Order.</span></li>
                <li class="c5 c3 li-bullet-0"><span class="c0"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-0">
                <li class="c2 li-bullet-0"><span class="c4">USE OF THE SERVICE</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c4"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-1 start">
                <li class="c5 li-bullet-0"><span class="c0">To use the Service, you must ensure that you have a functioning
                    device to use for accessing the Service and that you have an internet connection. When you purchase an
                    item on the Service, you need to pay using a credit or debit card accepted by the Service on an external
                    PCI-certified server.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">When you use the Service, you warrant that you (i) are legally
                    capable of entering into a binding agreement for the use of the Service and the purchase of items from
                    the Restaurant; (ii) are at least 18 years old; (iii) will use the Service only for your personal,
                    non-commercial use and not for any re-distribution of items; and (iv) will not infringe the intellectual
                    property rights or other rights of third parties when using the Service. Please note that it is an
                    offence for any person under the age of 18 to purchase, or attempt to purchase, alcohol or for any
                    person over the age of 18 to purchase alcohol on behalf of any person under the age of 18.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">You can use the Service only for its intended purposes. You may not
                    separate any individual component of the Service or incorporate it into your own or others&#39; programs
                    or software or exploit it in any other way for purposes other than those for which it was intended. You
                    may not assist, encourage or authorise any third party to modify, reverse engineer, decompile or
                    disassemble the Service or parts of the software thereof, whether in whole or in part, or create any
                    derivative works from all or any part of the Service.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">All prices and charges stated on the Service for all items etc. are
                    inclusive of all current direct and indirect taxes, including VAT.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">You must not manipulate transactions made on the Service in any way
                    or use the Service in contravention of applicable legislation nor permit or assist others to do
                    so.</span></li>
                <li class="c5 c3 li-bullet-0"><span class="c0"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-0">
                <li class="c2 li-bullet-0"><span class="c4">TERMS OF PAYMENT AND DELIVERY</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c4"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-1 start">
                <li class="c5 li-bullet-0"><span class="c0">When you purchase items on the Service, you will be asked to enter
                    your payment details to pay for items using your payment card. Your payment details will be saved and
                    stored by the Service on a secure, external and PCI-certified server. The Service uses the 3 digits on
                    the back of your payment card as a code to make payments through the Service secure. On the collection
                    or delivery of items, you may be asked to show your device displaying your Order number to the
                    Restaurant staff to allow the Restaurant to identify you and your Order. You are responsible for the
                    safekeeping of your order number for such identification.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">QuickFood will ensure that any information passing between the
                    Service and the server is encrypted. QuickFood will not be liable for any claims arising from the misuse
                    of your payment card where such misuse is a result of your failure to keep your payment card
                    secure.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">The Service procures delivery of the ordered items to you within
                    reasonable industry times. The Restaurant may notify you of an estimated time for delivery or collection
                    of your items. Please note that such estimates cannot be relied on or treated as guarantees.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">The Service will comply with applicable legislation and regulations
                    related to your use of the Service, including regulations concerning distance selling, data protection
                    and food safety.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">Handling fee. In addition to the price for the ordered items and, if
                    relevant, delivery, QuickFood will charge a small handling fee for each Order placed on the Service
                    (irrespective of whether the Restaurant accepts such Order). The handling fee applicable at any time
                    will be clearly stated on the general overview of your order before you place your order and complete
                    payment. When QuickFood charges the handling fee, we do not do so as agent for the Restaurant. The
                    handling fee is a service fee payable by you directly to us for use of our Service and is not refundable
                    under any circumstances.</span></li>
                <li class="c5 c3 li-bullet-0"><span class="c0"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-0">
                <li class="c2 li-bullet-0"><span class="c4">DURATION AND CHANGES</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c4"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-1 start">
                <li class="c5 li-bullet-0"><span class="c0">The Terms of Use apply for as long as you use the Service. However,
                    all Terms of Use in respect of liability, indemnification, etc., under which claims may be raised at a
                    later time, will remain effective after you have stopped using the Service.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">Change or cancellation of your Order. Once you have submitted and
                    paid for your Order, you will be entitled to change or cancel your Order on the Service until the
                    Restaurant has accepted it. The Order will have been accepted once you receive email confirmation of the
                    same. You may also edit any typing errors etc. directly on the Service until the Restaurant has accepted
                    your order.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">The Restaurant may reject your Order, for example if the Restaurant
                    no longer has the items in stock or if the Restaurant is too busy to be able to fulfil your Order within
                    a reasonable time. If the Restaurant rejects your Order you will receive notification of this.</span>
                </li>
                <li class="c5 li-bullet-0"><span class="c0">Refunds for cancelled or rejected Orders. When you complete an
                    Order, your bank or card issuer will ring-fence the amount owed. If you cancel the Order or if the
                    Restaurant rejects it, your bank or card issuer will release the amount back to your account (without
                    transferring the amount to QuickFood). Please note that this process may take up to 7 days.</span></li>
                <li class="c5 c3 li-bullet-0"><span class="c0"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-0">
                <li class="c2 li-bullet-0"><span class="c4">INTELLECTUAL PROPERTY RIGHTS</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c4"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-1 start">
                <li class="c5 li-bullet-0"><span class="c6">All patents, trademarks, service marks, designs, utility models,
                    unregistered trademarks, business or trade names (including internet domain names and email addresses),
                    copyright (including copyright in broadcasts, computer software and databases), semi-conductor
                    topography rights, database rights and design rights, inventions (including improvements on or additions
                    to inventions), discoveries, know-how and any other intellectual and industrial property rights and
                    rights of a similar or corresponding nature (&quot;Intellectual Property Rights&quot;) on </span><span
                        class="c6">www.QuickFood.io</span><span class="c0">&nbsp;or on the Service, including the QuickFood
                            brand, logo and get-up, community features, underlying software and technology, and any information,
                            user data and other material generated by the operation of the website or the Service belong to
                            QuickFood or its licensors. You acquire no claim for any right, title to or interest in the same and no
                            goodwill will inure to you by your use of the Service.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">Indemnification. You accept to indemnify QuickFood against all
                    direct and indirect losses and costs etc. relating to any claim concerning the infringement of a third
                    party&#39;s Intellectual Property Rights as a result of your use of the Service.</span></li>
                <li class="c5 c3 li-bullet-0"><span class="c0"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-0">
                <li class="c2 li-bullet-0"><span class="c4">ACCESS TO THE SERVICE AND INFORMATION ON THE SERVICE</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c4"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-1 start">
                <li class="c5 li-bullet-0"><span class="c6">QuickFood</span><span class="c0">&nbsp;runs the Service with the aim
                    of providing you with uninterrupted access. However, you acknowledge that QuickFood is under no
                    obligation to ensure that the Service is continuous and that QuickFood will not be liable where access
                    to the Service is suspended without notice, for example for maintenance purposes or due to
                    breakdown.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">QuickFood will seek to a commercially reasonable extent to ensure
                    that the information on QuickFood&#39;s website and on the Service is correct, true and fair at all
                    times.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">QuickFood is not responsible for ensuring that the prices and item
                    descriptions provided on the Service are correct. Please note that the Restaurant is responsible for the
                    contents, including the information, provided on the Service, and for ensuring that the information
                    about prices etc. is correct, true and fair at all times.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">You will be responsible for acquiring, installing, maintaining and
                    updating all connectivity equipment as may be necessary for you to access and use the Service and its
                    features and for ensuring that your use of the Service complies with the Terms of Use.</span></li>
                <li class="c5 c3 li-bullet-0"><span class="c0"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-0">
                <li class="c2 li-bullet-0"><span class="c4">LIABILITY</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c4"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-1 start">
                <li class="c5 li-bullet-0"><span class="c0">Nothing in these terms shall limit or exclude any liability to you:
                    (a) for death or personal injury caused by negligence; (b) for fraudulent misrepresentation; (c) for
                    breach of any term implied by the Consumer Rights Act 2015 and which, by law, may not be limited or
                    excluded; (d) under Part I of the Consumer Protection Act 1987; or (e) for any other liability that, by
                    law, may not be limited or excluded.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">Subject to clause 7.1, in no event shall we or the relevant
                    Restaurant be liable to you for any losses, and any liability we do have for losses you suffer arising
                    from an Order shall not exceed the charges payable by you for the relevant Order and is strictly limited
                    to losses that were reasonably foreseeable. Losses are foreseeable where they could be contemplated by
                    you and us at the time your Order is accepted by us on behalf of the Restaurant. We, and the relevant
                    Restaurant, will not be liable or responsible for any failure to perform, or delay in performance of,
                    any of our obligations under any Order that is caused by events outside our reasonable control. Please
                    remember that the legal contract for the supply and purchase of the items in your Order is between you
                    and the relevant Restaurant. We have no control over the actions or omissions of any Restaurant.</span>
                </li>
                <li class="c5 c3 li-bullet-0"><span class="c0"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-0">
                <li class="c2 li-bullet-0"><span class="c4">DATA PROTECTION</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c4"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-1 start">
                <li class="c5 li-bullet-0"><span class="c6">QuickFood</span><span class="c6">&nbsp;is committed to protecting
                    your privacy and security. Read more about how we process your personal data in QuickFood&#39;s
                </span><span class="c6">Pri</span><span class="c10">va</span><span class="c10">cy promise</span><span
                    class="c0">.</span></li>
                <li class="c5 c3 li-bullet-0"><span class="c0"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-0">
                <li class="c2 li-bullet-0"><span class="c4">OTHER TERMS</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c4"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-1 start">
                <li class="c5 li-bullet-0"><span class="c6">QuickFood</span><span class="c0">&nbsp;may amend these Terms of Use
                    from time to time. In case of such amendments, the new terms will be shown on the Service the next time
                    you use it and, if you are already a user of the Service, you may receive an email with the new terms.
                    The amended Terms of Use will take effect from the date specified.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">If any of the Terms of Use is held to be invalid or unenforceable
                    for any other reason, such Term(s) of Use must be considered not to form part of the Terms of Use, but
                    the validity and enforceability of the remainder of the Terms of Use will not be affected.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">The failure to enforce one or more of the Terms of Use against you
                    must not be considered to be a waiver of the right to do so.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">Governing law and venue. The Terms of Use are governed by and will
                    be interpreted in accordance with English law, except that if you live in a country (which, for these
                    purposes, includes Scotland or Northern Ireland) of the European Union other than England, there may be
                    certain mandatory applicable laws of your country which apply for your benefit and protection in
                    addition to or instead of certain provisions of English law. Any dispute must be brought before the
                    courts of England, except that (if you are a consumer and not a business user) and if you live in a
                    country (which, for these purposes, includes Scotland or Northern Ireland) of the European Union other
                    than England, you can choose to bring legal proceedings either in your country or in England, but if we
                    bring legal proceedings, we may only do so in your country.</span></li>
                <li class="c5 c3 li-bullet-0"><span class="c0"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-0">
                <li class="c2 li-bullet-0"><span class="c4">NOTICE OF LACK OF CONFORMITY, CANCELLATION RIGHT AND
                    COMPLAINTS</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c4"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-1 start">
                <li class="c5 li-bullet-0"><span class="c0">Problems with your Order. You may exercise remedies against the
                    Restaurant if there is a problem with your Order (for example, items in your Order are damaged or do not
                    match the description provided by the Restaurant). If there are any problems with your Order, you may
                    have one or more legal remedies available to you, depending on when you make us aware of the problem, in
                    accordance with your legal rights. If you believe the Service or any items ordered are damaged or do not
                    comply with the Order, you should inform us as soon as possible, preferably in writing, giving your
                    name, address and Order reference. Nothing in this section affects your legal rights.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">Cancellation right. Unless there is a problem with your Order, you
                    do not have any right to cancel an Order once it has been accepted by us on behalf of the
                    Restaurant.</span></li>
                <li class="c5 li-bullet-0"><span class="c6">Complaints. Until the end of 2020, you may file a complaint about
                    the Service or the items delivered by the Restaurant through the EU Commission&#39;s platform for online
                    dispute resolution on </span><span class="c10 c11"><a class="c7"
                        href="https://www.google.com/url?q=https://www.ec.europa.eu/consumers/odr&amp;sa=D&amp;source=editors&amp;ust=1679325102154292&amp;usg=AOvVaw0AlTQiebwPIRSnGd7SVUBi">www.ec.europa.eu/consumers/odr</a></span><span
                            class="c0">.</span></li>
                <li class="c5 li-bullet-0"><span class="c6">If you are not satisfied with the items delivered and/or if you wish
                    to give notice of a problem or otherwise file a complaint about an Order, you must first contact the
                    Restaurant. If you are not satisfied with the Restaurant&#39;s response, you may notify QuickFood
                    thereof no later than 24 hours after you have given notice of the problem or complaint. You may contact
                    QuickFood using the contact details available on </span><span class="c9"><a class="c7"
                        href="https://www.google.com/url?q=http://www.quickfood.io&amp;sa=D&amp;source=editors&amp;ust=1679325102155099&amp;usg=AOvVaw2SYS2x8Wfk3Iz9crHtMuB7">www.QuickFood.io</a></span><span
                            class="c6">&nbsp;</span><span class="c0">&nbsp;Please note QuickFood has no obligation to assist with
                                any such complaints and shall have no liability in respect of the same. Your contract is with the
                                Restaurant and therefore must be resolved with the Restaurant. QuickFood will assist in the resolution
                                where it can. If you would like to discuss any issue with the Service, please contact us
                                directly.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">These terms and any document expressly referred to in them represent
                    the entire agreement between you and the relevant Restaurant in relation to the subject matter of any
                    Order. We are required by law to advise you that Orders may be concluded in the English language only
                    and that no public filing requirements apply.</span></li>
                <li class="c5 c3 li-bullet-0"><span class="c0"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-0">
                <li class="c2 li-bullet-0"><span class="c4">ACCEPTANCE OF THE TERMS OF USE</span></li>
                <li class="c2 c3 li-bullet-0"><span class="c4"></span></li>
            </ul>
            <ul class="c1 lst-kix_wv6na3xgpjfx-1 start">
                <li class="c5 li-bullet-0"><span class="c0">By completing an Order or the registration of your user profile with
                    QuickFood, you acknowledge acceptance of the Terms of Use.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">Your agreement on the use of the Service is available at all times
                    on request to QuickFood as QuickFood stores it electronically. Your agreement on the purchase of items
                    from the Restaurant (i.e. your Order confirmation) is stored electronically by the Restaurant and is
                    available at all times on direct request to the Restaurant.</span></li>
            </ul>
            <p class="c3 c8"><span class="c12"></span></p>
        </div>
    )
}