import React, {useState,useEffect } from "react";
import {countryCodeList} from './countries'

const Selector = (props) => {
  const [countries, setCountries] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState("United Kingdom");
  const [flag, setFlag] = useState("https://storage.histreet-app.com/flags/i24/United-Kingdom-Flag.png");
  const [currency, setCurrency] = useState("£");
  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    props.showCurrency(currency);
  }, [currency]);
  
  // const handleCurrency = (event) => {
  //   props.showCurrency(currency)
  // };

  return (
    <div className="w-72 font-medium h-80 z-10">
      <div
        onClick={() => setOpen(!open)}
        className={`bg-gray w-full p-2 flex items-end cursor-pointer justify-end rounded text-base whitespace-nowrap  ${
          !selected && "text-gray-700"
        }`}
      >
        {
            flag ?
            <img 
                className=" mr-6 h-6 w-6 object-contain"
                src={flag}
                alt={selected}
            /> :
            <div className="relative">
              <img 
                className="mr-6 h-6 w-6 object-contain"
                src={require('../images/commonFlag.png')}
                alt={selected}
              />
              <div className="absolute top-0 left-2">{selected.charAt(0)}</div>
            </div>
            
        }
        

        {selected
          ? selected?.length > 25
            ? selected?.substring(0, 25) + "..."
            : selected
          : "Select Country"}
        {/* <BiChevronDown size={20} className={`${open && "rotate-180"}`} /> */}
      </div>
      <ul
        className={`bg-gray mt-2 overflow-y-auto ${
          open ? "max-h-60 bg-white drop-shadow-lg" : "max-h-0"
        } `}
      >
        <div className="drop-shadow-lg flex items-center sticky top-0 z-10">
          {/* <AiOutlineSearch size={18} className="text-gray-700" /> */}
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value.toLowerCase())}
            placeholder="Enter country name"
            className="w-full bg-neutral-200 placeholder:text-gray-700 p-2 outline-none "
          />
        </div>
        {countryCodeList?.map((country) => (
          <li
            key={country?.Name}
            className={`p-2 cursor-pointer text-base hover:bg-sky-600 hover:text-white flex align-center text-left
            ${
              country?.Name?.toLowerCase() === selected?.toLowerCase() &&
              "bg-sky-600 text-white"
            }
            ${
              country?.Name?.toLowerCase().startsWith(inputValue)
                ? "block"
                : "hidden"
            }`}
            onClick={() => {
              if (country?.Name?.toLowerCase() !== selected.toLowerCase()) {
                setSelected(country?.Name);
                setFlag(country?.Flag);
                setCurrency(country?.CurrencySymbol);
                setOpen(false);
                setInputValue("");
              }
            }}
          >
            {
              country.Flag ?
              <img 
                  className="mr-6 h-6 w-6 object-contain"
                  src={country?.Flag}
                  alt={country?.Name}
              />:
              <div className="relative">
                <img 
                  className="mr-6 h-6 w-6 object-contain"
                  src={require('../images/commonFlag.png')}
                  alt={selected}
                />
                <div className="absolute top-0 left-2 z-0">{country?.Name.charAt(0)}</div>
              </div>
              
            }
            
            {country?.Name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Selector;